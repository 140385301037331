<template>
  <div id="create-customer">
    <h1>Ajouter un client</h1>
    <div class="create">
        <form>
            <div class="form-div">
                <div class="label-input">
                    <label for="name">Nom</label>
                    <input class="input-form" type="text" name="name" placeholder="John Doe" v-model="input.name" required/>
                </div>
                <div class="label-input">
                    <label for="company">Entreprise</label>
                    <input class="input-form" type="text" name="company" placeholder="Nom + Dénomination" v-model="input.cpy" required/>
                </div>
            </div>
            <div class="form-div">
                <div class="label-input">
                    <label for="address">Adresse</label>
                    <input class="input-form" type="text" name="address" v-model="input.address" placeholder="Numéro et rue" required/>
                </div>
                <div class="label-input">
                    <label for="zip">Code postal</label>
                    <input class="input-form" type="number" name="zip" v-model="input.zip" placeholder="XXXXX" required/>
                </div>
                <div class="label-input">
                    <label for="city">Ville</label>
                    <input class="input-form" type="text" name="city" v-model="input.city" placeholder="ex: Paris" required/>
                </div>
                <div class="label-input">
                    <label for="country">Pays</label>
                    <input class="input-form" type="text" name="country" v-model="input.country" placeholder="FR" required/>
                </div> 
            </div>
            <div class="form-div">
                <div class="label-input">
                    <label for="email">Email</label>
                    <input class="input-form" type="email" name="email" placeholder="Adresse email" v-model="input.email" required/>
                </div>
                <div class="label-input">
                    <label for="phone">Téléphone</label>
                    <input class="input-form" type="tel" name="phone" placeholder="Numéro de téléphone" v-model="input.phone" pattern="^(?:0|\(?\+33\)?\s?|0033\s?)[1-79](?:[\.\-\s]?\d\d){4}$" required/>
                </div>
            </div>
            <div class="form-div">
                <div class="label-input">
                    <label for="siret">N. SIRET</label>
                    <input class="input-form" type="number" name="siret" placeholder="784 671 695 00087" v-model="input.siret" required>
                </div>
                <div class="label-input">
                    <label for="autopay">Paiement systématique</label>
                    <select class="input-form" v-model="input.autopay">
                        <option value="0">Non</option>
                        <option value="1">Oui</option>
                    </select>
                </div>
            </div>
            <div class="form-div">
                <div class="label-input">
                    <label for="wallet">Portefeuille</label>
                    <SelectWallet class="input-form" :walletList="$store.state.WalletList" @on-wallet="handleWallet"></SelectWallet>
                </div>
                <div class="label-input">
                    <label for="collaborator">Collaborateur</label>
                    <SelectUser class="input-form" :UserList="state.UserList" @on-collab="handleCollab" ></SelectUser>
                </div>
            </div>
            <div class="form-submit">
                <button type="button" @click="createCustomer()">Créer</button>
            </div>
        </form>
    </div>
  </div>
</template>

<script>
import SelectUser from '../../components/utils/SelectUser.vue';
const axios = require('axios');
const findCollaborator = require('../../scripts/collaborator/findUserIdByName');
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import SelectWallet from '../../components/utils/SelectWallet.vue';

export default {
    data() {
        return {
            input: {
                name: "",
                cpy: "",
                address: "",
                zip: "",
                city: "",
                country: "FR",
                email: "",
                phone: "",
                siret: "",
                autopay: 0,
                wallet: "1",
                collaborator: "1"
            },
            state: {
                UserList: this.$store.state.UserList
            }
        };
    },
    methods: {
        createCustomer() {
            if (!this.input.cpy || !this.input.address || !this.input.zip || !this.input.city || !this.input.country || !this.input.email || !this.input.phone) {
                return;
            }
            if (this.input.cpy === "" || this.input.address === "" || this.input.zip === 0 || this.input.city === "" || this.input.country === "" || this.input.email === "" || this.input.phone === 0) {
                return;
            }
            axios.post(`${this.$store.state.BaseUrl}/customers/new`, {
                name: this.input.name,
                company: this.input.cpy,
                adress: this.input.address,
                zip: this.input.zip,
                city: this.input.city,
                country: this.input.country,
                email: this.input.email,
                phone: this.input.phone,
                siret: this.input.siret,
                autopay: this.input.autopay,
                wallet_id: this.input.wallet,
                collaborator_id: this.input.collaborator
            }, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                let id = res.data.id;
                axios.get(`${this.$store.state.BaseUrl}/tasks/init/${id}`, {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.Bearer}`
                    }
                }).then(res => {
                    createToast({ title: "Client créé" }, { type: "success", showIcon: true, onClose: this.$router.push('/customers') });
                }).catch(err => {
                    console.log(err);
                    createToast({ title: "Erreur", description: "Erreur interne" }, { type: "danger", showIcon: true });
                });
            }).catch(err => {
                createToast({ title: "Erreur", description: "Erreur interne" }, { type: "danger", showIcon: true });
            })
        },
        handleCollab(val) { 
            this.input.collaborator = val;
        },
        handleWallet(val) { 
            this.input.wallet = val;
        }
    },
    mounted() {

    },
    components: { SelectUser, SelectWallet }
}
</script>

<style scoped>


#create-customer {
}

.create {
    display: flex;
    justify-content: center;
    align-items: center;
}

.create form {
    display: flex;
    flex-direction: column;
    width: 40%;
}

.form-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.label-input {
    display: flex;
    flex-direction: column;
    width: 45%;
    margin-top: 10px;
}

.input-form {
    text-align: center;
    border-radius: 10px;
    height: 30px;

}

input:focus {
    outline: none;
    border: 1px solid lightseagreen;
}

.form-submit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

button {
    width: 30%;
    margin-top: 20px;
    height: 40px;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    background-color: lightseagreen;
    color: white;
}

button:hover {
    background-color: white;
    color: lightseagreen;
    border: 1px solid lightseagreen;
}

/* Remove arrows for input type number*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}

::placeholder {
    text-align: center; 
}

/* or, for legacy browsers */
::-webkit-input-placeholder {
    text-align: center;
}

:-moz-placeholder { /* Firefox 18- */
    text-align: center;  
}

::-moz-placeholder {  /* Firefox 19+ */
    text-align: center;  
}

:-ms-input-placeholder {  
    text-align: center; 
}
</style>

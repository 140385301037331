<template>
    <div id="ticket-root">
        <div id="left">
            <h3 v-if="!logs.length">Aucune activité récente</h3>
            <div v-else>
                <h3>Historique</h3>
                <ul>
                    <li v-for="log in logs" v-html="log.content"></li>
                </ul>
            </div>
            
        </div>
        <div id="right">
            <div id="info">
                <table>
                    <tr>
                        <td>Titre :</td>
                        <td>{{ticket.title}}</td>
                    </tr>
                    <tr>
                        <td>Description :</td>
                        <td>{{ticket.description}}</td>
                    </tr>
                    <tr>
                        <td>Client :</td>
                        <td>{{getCustomer(ticket.customer_id)}}</td>
                    </tr>
                    <tr>
                        <td>Créé le :</td>
                        <td>{{formatDate(ticket.created_at)}}</td>
                    </tr>
                    <tr>
                        <td>Collaborateur :</td>
                        <td>{{getUser(ticket.collaborator_id)}}</td>
                    </tr>
                    <tr>
                      <td>A faire avant :</td>
                      <td>{{formatEndDate(ticket.end_at)}}</td>
                    </tr>
                </table>
                <button type="button" @click="$router.push('/tickets/edit/' + ticket.id)">Modifier les informations</button>
            </div>
            <div id="post-it">
              <h3>Post-It</h3>
              <textarea name="post-it" class="post-it-input" v-model="ticket.post_it">{{ticket.post_it}}</textarea>
              <div class="post-it-buttons">
                  <button @click="savePostIt()">Sauvegarder</button>
              </div>
            </div>
            <div id="update">
                <div class="status-div">
                    <h4>Priorité</h4>
                    <select v-model="priority" class="input-form">
                        <option value="bas">Bas</option>
                        <option value="normal">Normale</option>
                        <option value="haute">Haute</option>
                    </select>
                </div>
                <div class="status-div">
                    <h4>Statut</h4>
                    <select v-model="status" class="input-form">
                        <option value="nouveau">Nouveau</option>
                        <option value="en cours">En cours</option>
                        <option value="OK">Résolu</option>
                    </select>
                </div>
                <button @click="submitStatus(status, priority)">Enregistrer</button>
            </div>
        </div>
    </div>

</template>

<script>
import Badge from '../../components/utils/Badge.vue';
const axios = require('axios');
const findCustomerName = require('../../scripts/customer/findCompany');
const findCollaboratorName = require('../../scripts/collaborator/findUserNameById');
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    name: "ViewTicket",
    data() {
        return {
            ticket: {},
            logs: [],
            status: '',
            priority: '',
            post_it: '',
        };
    },
    mounted() {
        if (!this.$route.params.id) {
            this.$router.push("/");
        }
        else {
            this.getTicket(this.$route.params.id);
        }
    },
    beforeRouteUpdate(to, from, next) {
        this.getUser(to.params.id);
        next();
    },
    methods: {
        formatEndDate(date) {
          if (date === null) {
            return 'Aucune date';
          }
          else {
            return this.formatDate(date);
          }
        },
        submitStatus(status, priority) {
            axios.put(`${this.$store.state.BaseUrl}/tickets/${this.ticket.id}`, {
                status: status,
                priority: priority
            }, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                //toast notif
                axios.post(`${this.$store.state.BaseUrl}/tickets/${this.ticket.id}/logs`, {
                    content: `${this.$store.state.User.first_name} ${this.$store.state.User.name} à défini le status sur <strong>${status}</strong> et la priorité sur <strong>${priority}</strong>`
                }, {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.Bearer}`
                    }
                }).then(res => {
                    createToast({ title: "Ticket mis à jour !" }, { type: "success", showIcon: true });
                }).catch(err => {
                    console.log(err);
                    createToast({ title: "Erreur", description: "Erreur du serveur" }, { type: "danger", showIcon: true });
                })
            }).catch(err => {
                console.log(err);
            });
        },
        formatDate(date) {
            var opt = {
                timeZone: "Europe/Paris",
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
            };
            return new Date(date).toLocaleDateString("fr-FR", opt);
        },
        selectGoodOption(value, value2) {
            if (value === value2) {
                return "selected";
            }
            return "none";
        },
        getUser(id) {
            return findCollaboratorName(id, this.$store.state.UserList);
        },
        getCustomer(id) {
            return findCustomerName(id, this.$store.state.CustomersList);
        },
        getTicket(id) {
            axios.get(`${this.$store.state.BaseUrl}/tickets/${id}`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                this.ticket = res.data;
                this.status = this.ticket.status;
                this.priority = this.ticket.priority;
                axios.get(`${this.$store.state.BaseUrl}/tickets/${id}/logs`, {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.Bearer}`
                    }
                }).then(res => {
                    this.logs = res.data.reverse();
                }).catch(err => {
                    console.log(err);
                });
            }).catch(err => {
                console.log(err);
                this.$router.push("/tickets");
            });
        },
        savePostIt() {
            axios.post(`${this.$store.state.BaseUrl}/tickets/${this.ticket.id}/post_it`, {
                post_it: this.ticket.post_it
            }, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                createToast({ title: "Post-it sauvegardé" }, { type: "success", showIcon: true });
            }).catch(err => {
                console.log(err);
                return createToast({ title: "Erreur", description: "Erreur du serveur" }, { type: "danger", showIcon: true });
            });
        },
    },
    components: { Badge }
}
</script>

<style scoped>

#ticket-root {
    display: flex;
    margin: 10px;
    justify-content: space-between;
}

#left, #info, #update, #post-it {
    margin: 20px;
    background-color: whitesmoke;
    border-radius: 20px;
    padding: 10px;
    border: 2px solid lightseagreen;
    word-wrap: break-word;
}

#left, #right {
    min-width: 48%;
    max-height: 355px;
    min-height: 355px;;
}

button {
    width: 30%;
    margin-top: 20px;
    height: 40px;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    background-color: lightseagreen;
    color: white;
}

button:hover {
    background-color: white;
    color: lightseagreen;
    border: 1px solid lightseagreen;
}

.input-form {
    text-align: center;
    border-radius: 10px;
    height: 30px;
    width: 30%;
}

.input-form:focus {
    outline: none;
    border: 1px solid lightseagreen;
}

ul {
  list-style: none;
}
li {
  position: relative;
  padding: 10px;
}
li a {
  color: gray;
}
li:before {
  content: '';
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: lightseagreen;
  position: absolute;
  left: -15px;
  top: 50%;
  transform: translateY(-40%);
}

li:not(:last-child):after {
  content: '';
  width: 2px;
  height: 100%;
  background: lightseagreen;
  position: absolute;
  left: -8px;
  top: 50%;
}

#left {
    overflow: scroll;
}

.post-it-input {
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
    height: 50%;
    background-color: whitesmoke;
    border: none;
    overflow: auto;
    outline: none;
    box-shadow: none;
    resize: none;
}

.post-it-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 20px;
}

.post-it-buttons button {
    cursor: pointer;
    width: 30%;
    border-radius: 20px;
    border: none;
    height: 30px;
    background-color: lightseagreen;
    color: white;
    margin-bottom: 20px;
}

.post-it-buttons button:hover {
    background-color: white;
    color: lightseagreen;
    border: 1px solid lightseagreen;
}
</style>

<template>
    <div id="view-root">
        <div id="form">
            <h3>Inscription</h3>
            <label>Email</label>
            <input class="input-form" v-model="register.email" placeholder="">
            <label>Prénom</label>
            <input class="input-form" v-model="register.first_name" placeholder="">
            <label>Nom</label>
            <input class="input-form" v-model="register.name" placeholder="">
            <label>Téléphone</label>
            <input class="input-form" v-model="register.phone" type="number" placeholder="">
            <label>Mot de passe</label>
            <input class="input-form" v-model="register.password" type="password" placeholder="">
            <button type="button" @click="registerUser()">Inscription</button>
        </div>
    </div>
</template>

<script>
const axios = require('axios');
export default {
    name: 'Register',
    data() {
        return {
            register: {
                email: '',
                password: '',
                phone: '',
                name: '',
                first_name: ''
            }
        }
    },
    methods: {
        registerUser() {
            if (!this.register.email || !this.register.password || !this.register.phone || !this.register.name || !this.register.first_name) {
                return;
            }
            console.log(this.register);
            console.log('ici');
            axios.post(`${this.$store.state.BaseUrl}/auth/register`, {
                email: this.register.email,
                password: this.register.password,
                phone: this.register.phone,
                name: this.register.name,
                first_name: this.register.first_name
            }, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                this.$router.push('/collaborators');
            }).catch(err => console.log(err))
        },
    },
    mounted() {
        
    }
}
</script>

<style scoped>
#view-root {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

#form {
    width: 30%;
    display: flex;
    flex-direction: column;
}

button {
    margin-top: 20px;
}
</style>

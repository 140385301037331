findCollaborator = (name, UserList) => {
    var id = 1;
    UserList.forEach(element => {
        if (element.name.includes(name)) {
            id = element.id;
            return id;
        }
    });
    return id;
}

module.exports = findCollaborator;

<template>
    <div id="component-root" class="card">
      <h1>Mandat - {{mandat.debitor_name}}</h1>
      <table>
        <tr>
          <span>Numéro Unique de Réference du mandat (RUM) : </span>
          <span class="right">{{mandat.rum}}</span>
        </tr>
        <tr>
          <span>Numéro IBAN du client : </span>
          <span class="right">{{mandat.debitor_iban}}{{mandat.debitor_rib}}</span>
        </tr>
        <tr>
          <span>Numéro BIC du client : </span>
          <span class="right">{{mandat.debitor_bic}}</span>
        </tr>
        <tr>
          <span>Date de signature du mandat : </span>
          <span class="right">{{mandat.created_at}}</span>
        </tr>
        <tr>
          <span>Type de mandat (CORE/B2B) :</span>
          <span class="right">{{mandat.type}}</span>
        </tr>
        <tr>
          <span>Numéro ICS assigné au mandat :</span>
          <span class="right">{{mandat.ics}}</span>
        </tr>
      </table>
      <button @click="setActive(mandat.id, mandat.active)">Actif : {{ mandat.active }}</button>
    </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ViewMandat',
  data() {
    return {
      mandat: {}
    }
  },
  methods: {
    setActive(id, status) {
            let value = true;
            if (status === 1) {
                value = false;
            }
            axios.post(`${this.$store.state.BaseUrl}/mandat/active/${id}`, {
                value: value
            },{
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                this.$router.go();
            }).catch(err => {
                console.log(err);
            })
      },
    getMandat(id) {
      axios.get(`${this.$store.state.BaseUrl}/mandat/${id}`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.Bearer}`
        }
      }) 
        .then(res => {
          this.mandat = res.data[0];
        })
        .catch(err => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getMandat(this.$route.params.id);
  },
}
</script>

<style scoped>
.card {
  padding-top: 20px;
  background-color: whitesmoke;
  width: 80%;
  border-radius: 10px;
  margin-inline: 10%;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  transition: 0.3s;
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

table {
  text-align:left;
  width: 100%;
}

table tr {
  margin: 5px;
  width: 100%;
}

.right {
  margin-left: 10%;
  float: right;
}

span {
  margin: 5px;
}

</style>

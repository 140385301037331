<template>
    <div id="view-root">
        <div id="bill-top">
            <h3>Historique des paiements</h3>
            <div id="bill-bar">
              <div id="change-period">
                <span>Période : </span>
                <select v-model="period_idx" @change="updateDate()">
                  <option v-for="period in periods" :value="period.idx">{{period.name}}</option>
                </select>
                <input type="number" v-model="year" @change="updateDate()">
              </div>
              <Dropdown class="sort" :buttonText="'Trier par'">
                    <ul>
                        <li v-if="isSorted" @click="sortBill('normal')">Annuler le tri</li>
                        <li @click="sortBill('alpha')">A-Z</li>
                        <li @click="sortBill('wallet')">Portefeuille</li>
                    </ul>
                </Dropdown>
              <button type="button" @click="startBill">Facturer via PennyLane</button>
            </div>
        </div>
        <div id="bill">
            <table>
                <tr>
                    <th>Date</th>
                    <th>Client</th>
                    <th>Portefeuille</th>
                    <th>Produit</th>
                    <th>Quantité</th>
                    <th>Montant (€)</th>
                    <th>Statut</th>
                    <th>Actions</th>
                </tr>
                <tr v-for="bill in billList">
                    <td>{{formatDate(bill.order_date)}}</td>
                    <td>{{getName(bill.customer_id)}}</td>
                    <td>{{getWallet(bill.wallet_id)}}</td>
                    <td>{{bill.product_name}}</td>
                    <td>{{bill.quantity}}</td>
                    <td>{{getAmount(bill.product_price, bill.quantity)}}</td>
                    <td>{{bill.status}}</td>
                    <td><fa @click="deleteBill(bill.id)" class="del-bill" icon="xmark"></fa></td>
                </tr>
                <span v-if="!billList.length" class="error-message">Aucun paiement pour cette période</span>
            </table>
        </div>
        <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
    </div>
</template>

<script>
const findCustomer = require('../../scripts/customer/findCompany');
const _ = require('underscore');
const findWalletName = require('../../scripts/wallets/fileWalletNameById');
import ConfirmDialog from '../../components/UI/ConfirmDialog.vue';
import Dropdown from '../../components/utils/Dropdown.vue';
const axios = require('axios');
export default {
    name: 'Global Billing',
    data() {
        return {
            billList: [],
            bill_cpy: [],
            isSorted: false,
            period_idx: 4,
            year: new Date().getFullYear(),
            periods: [
              {
                idx: 0,
                name: 'Janvier',
                month_code: '01',
              },
              {
                idx: 1,
                name: 'Février',
                month_code: '02',
              },
              {
                idx: 2,
                name: 'Mars',
                month_code: '03',
              },
              {
                idx: 3,
                name: 'Avril',
                month_code: '04',
              },
              {
                idx: 4,
                name: 'Mai',
                month_code: '05',
              },
              {
                idx: 5,
                name: 'Juin',
                month_code: '06',
              },
              {
                idx: 6,
                name: 'Juillet',
                month_code: '07',
              },
              {
                idx: 7,
                name: 'Août',
                month_code: '08',
              },
              {
                idx: 8,
                name: 'Septembre',
                month_code: '09',
              },
              {
                idx: 9,
                name: 'Octobre',
                month_code: '10',
              },
              {
                idx: 10,
                name: 'Novembre',
                month_code: '11',
              },
              {
                idx: 11,
                name: 'Décembre',
                month_code: '12',
              },
            ],
            startDate: '',
            endDate: ''
        }
    },
    components: { ConfirmDialog, Dropdown },
    methods: {
        sortBill(type) {
            if (type === 'normal') {
                this.billList = this.bill_cpy;
                this.isSorted = false;
            }
            if (type === 'alpha') {
                if (this.isSorted) {
                    this.billList = this.bill_cpy;
                    this.isSorted = false;
                }
                this.bill_cpy = this.billList;
                this.billList = _.sortBy(this.billList, 'customer_id');
                this.isSorted = true;
            }
            if (type === 'wallet') {
                if (this.isSorted) {
                    this.billList = this.bill_cpy;
                    this.isSorted = false;
                }
                this.bill_cpy = this.billList;
                this.billList = _.sortBy(this.billList, 'wallet_id');
                this.isSorted = true;
            }
        },
        formatDate(date) {
            var opt = {
                timeZone: "Europe/Paris",
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric"
            };
            return new Date(date).toLocaleDateString("fr-FR", opt);
        },
        updateDate() {
          let monthNumber = this.period_idx + 1;

          // Calculate the first date by using the number variable to set the month and hard-coding the day and year
          let firstDate = new Date(this.year, monthNumber-1, '01');

          // Calculate the second date by adding one month to the first date
          let secondDate = new Date(this.year, firstDate.getMonth()+1, firstDate.getDate());

          // Format the dates using the YYYY-MM-DD format
          this.startDate = firstDate.toISOString().slice(0, 10);
          this.endDate = secondDate.toISOString().slice(0, 10);
          this.fetchAllBills();
        },
        getCurrentMonth() {
            var date = new Date();
            var month = date.getMonth();
            return month;
        },
        getAmount(price, quantity) {
            return price * quantity;
        },
        getWallet(id) {
            return findWalletName(id, this.$store.state.WalletList);
        },
        getName(id) {
            return findCustomer(id, this.$store.state.CustomersList);
        },
        async deleteBill(id) {
            const ok = await this.$refs.confirmDialog.show({
                title: 'Supprimer cette facturation',
                message: 'Êtes vous sûr de vouloir supprimer cette facturation ? Cette action est irréversible.',
                okButton: 'Supprimer',
            })
            if (ok) {
                axios.delete(`${this.$store.state.BaseUrl}/billing/${id}`, {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.Bearer}`,
                    }
                }).then(res => {
                    this.$router.go();
                }).catch(err => {
                    console.log(err);
                    createToast({ title: "Erreur", description: "Erreur du serveur" }, { type: "danger", showIcon: true });
                })
            }
        },
        startBill() {
          this.$router.push({ name: "Export Billing", params: { startDate: this.startDate, endDate: this.endDate } });
        },
        fetchAllBills() {
            axios.get(`${this.$store.state.BaseUrl}/billing/range?startDate=${this.startDate}&endDate=${this.endDate}`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                this.billList = res.data.reverse();
            }).catch(err => {
                console.log(err);
            })
        },
    },
    mounted() {
        let d = new Date();
        let month = d.getMonth();
        let year = d.getFullYear();
        let endYear = year;
        let end = month + 2;
        if (month + 2 > 12) {
            end = '01';
            endYear = year + 1;
        } else {
            this.period_idx = month;
        }
        let start = month + 1;

        this.startDate = year + '-' + start + '-' + '01';
        this.endDate = endYear + '-' + end + '-' + '01';
        this.period_idx = this.getCurrentMonth();
        this.fetchAllBills();
    }
}
</script>

<style scoped>
table {
    margin-top: 10px;
    width: 100%;
}

#bill-top {
    padding: 5px;
    margin-inline: 2%;
    border-bottom: 2px solid lightseagreen;
}

.error-message {
  text-align: center;
}

#bill-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.del-bill {
    margin-left: 10px;
    font-size: 20px;
}

#change-period input {
    margin-left: 10px;
}
</style>

<template>
    <div id="view-root">
        <div id="create-mandat">
            <h2>Créer un mandat</h2>
            <label class="label-input">Client</label>
            <SelectCustomer :CustomerList="$store.state.CustomersList" v-model="mandat.debitor_name" class="input-form" @on-customer="handleCustomer"></SelectCustomer>
            <label class="label-input">Type</label>
            <select class="input-form" v-model="mandat.type">
              <option value="core">CORE</option>
              <option value="b2b">B2B</option>
            </select>
            <label class="label-input">Numéro RUM (si non précisé, il sera créé)</label>
            <input class="input-form" type="text" v-model="mandat.rum" placeholder="Numéro RUM" required>
            <label class="label-input">Partie 1 IBAN (FR76)</label>
            <input class="input-form" type="text" v-model="mandat.iban_s" placeholder="FR76" required>
            <label class="label-input">Partie 2 IBAN</label>
            <input class="input-form" type="text" v-model="mandat.iban" placeholder="Numéro IBAN (apres FR76)" required>
            <label class="label-input">BIC</label>
            <input class="input-form" type="text" v-model="mandat.bic" placeholder="Code BIC" required>
            <label class="label-input">Date de signature du mandat</label>
            <input class="input-form" type="text" v-model="mandat.signature_date" placeholder="JJ/MM/AAAA" required>
            <label class="label-input">Numéro ICS</label>
            <input class="input-form" type="text" v-model="mandat.ics" placeholder="Numéro ICS" required>

            <button @click="createMandat()" type="button">Créer</button>
        </div>
    </div>
</template>

<script>
const axios = require('axios');
import { createToast } from 'mosha-vue-toastify';
import SelectCustomer from '../../components/utils/SelectCustomer.vue';
import 'mosha-vue-toastify/dist/style.css';
export default {
    name: 'Create Mandat',
    components: {
        SelectCustomer
    },
    data() {
        return {
            mandat: {
                debitor_name: null,
                type: 'core',
                iban_s: "FR76",
                iban: null,
                bic: null,
                signature_date: null,
                ics: null,
                rum: null
            }
        }
    },
    mounted() {
      this.mandat.ics = this.$store.state.defaultICS
    },
    methods: {
        handleCustomer(val) {
            this.mandat.debitor_name = val;
        },
        createMandat() {
            if (!this.mandat.debitor_name || !this.mandat.iban || !this.mandat.bic || !this.mandat.ics) {
                console.log(this.mandat);
                return;
            }
            axios.post(`${this.$store.state.BaseUrl}/mandat/new`, {
                mandat: this.mandat,
            }, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                createToast({ title: "Mandat Créé !" }, { type: "success", showIcon: true, onClose: this.$router.push({name: 'Mandats'}) });
            }).catch(err =>{
                createToast({ title: "Erreur", description: "Erreur du serveur" }, { type: "danger", showIcon: true });
                console.log(err)
            });
        }
    },
}
</script>

<style scoped>
#create-mandat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.label-input {
   margin-top: 10px;
}

.input-form {
    text-align: center;
    border-radius: 10px;
    margin-bottom: 10px;
    height: 30px;
    width: 30%;
}
textarea.input-form {
    overflow: auto;
    outline: none;
    box-shadow: none;
    resize: none;
}

input:focus {
    outline: none;
    border: 1px solid lightseagreen;
}

button {
    width: 30%;
    margin-top: 20px;
    height: 40px;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    background-color: lightseagreen;
    color: white;
}

button:hover {
    background-color: white;
    color: lightseagreen;
    border: 1px solid lightseagreen;
}

/* Remove arrows for input type number*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}

::placeholder {
    text-align: center; 
}

/* or, for legacy browsers */
::-webkit-input-placeholder {
    text-align: center;
}

:-moz-placeholder { /* Firefox 18- */
    text-align: center;  
}

::-moz-placeholder {  /* Firefox 19+ */
    text-align: center;  
}

:-ms-input-placeholder {  
    text-align: center; 
}
</style>

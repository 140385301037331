<template>
  <div id="component-root">
    <table>
        <tr>
            <th>Id</th>
            <th>Titre</th>
            <th>Statut</th>
            <th>Priorité</th>
        </tr>
        <tr v-for="ticket in UserTickets" @click="viewTicket(ticket.id)" class="ticket-row">
            <td>{{ticket.id}}</td>
            <td>{{ticket.title}}</td>
            <td>{{ticket.status.toUpperCase()}}</td>
            <td class="badge-container">
            <Badge :text="ticket.priority.toUpperCase()" :type="selectType(ticket.priority)"></Badge>
            </td>
        </tr>
    </table>
  </div>
</template>

<script>
import Badge from '../utils/Badge.vue';
const axios = require('axios')

export default {
    name: "UserTickets",
    data() {
        return {
            UserTickets: []
        };
    },
    props: {
        userId: {
            required: true
        }
    },
    methods: {
        getUserTickets() {
            axios.get(`${this.$store.state.BaseUrl}/tickets/user/${this.userId}`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                this.UserTickets = res.data;
            }).catch(err => {
                console.log(err);
            });
        },
        viewTicket(id) {
            this.$router.push({ name: "Ticket", params: { id: id } });
        },
        selectType(priority) {
            switch(priority) {
                case 'bas':
                    return 'success';
                case 'normal':
                    return 'info';
                case 'haute': 
                    return 'danger';
                default: 
                    return 'warning';
            }
        }
    },
    mounted() {
        this.getUserTickets();
    },
    components: { Badge }
}
</script>

<style scoped>

#component-root {
    padding: 10px;
}

table {
    width: 100%;
}

.badge-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ticket-row {
    cursor: pointer;
}
</style>

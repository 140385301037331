<template>
    <div id="tickets">
        <div class="customers-top">
            <h3>Liste des tickets </h3>
            <button class="view_tickets" @click="$router.push('/tickets')">Tickets en attente</button>
                <Dropdown class="sort" :buttonText="'Trier par'">
                    <ul>
                        <li v-if="isSorted" @click="sortTickets('normal')">Annuler le tri</li>
                        <li @click="sortTickets('date')">Plus récents</li>
                        <li @click="sortTickets('status')">Statut</li>
                        <li @click="sortTickets('priority')">Priorité</li>
                        <li @click="sortTickets('company')">Client</li>
                        <li @click="sortTickets('user')">Collaborateur</li>
                    </ul>
                </Dropdown>
                <input type="search" @input="searchArray" placeholder="Chercher un ticket...">
        </div>
        <table>
            <tr>
                <th>Id</th>
                <th>Client</th>
                <th>Titre</th>
                <th>Date de création</th>
                <th>Collaborateur</th>
                <th>Priorité</th>
                <th>Statut</th>
                <th>Actions</th>
            </tr>
            <tr v-for="ticket in ticketList">
                <td>{{ticket.id}}</td>
                <td>{{getCustomerName(ticket.customer_id)}}</td>
                <td>{{ticket.title}}</td>
                <td>{{formatDate(ticket.created_at)}}</td>
                <td>{{getCollab(ticket.collaborator_id)}}</td>
                <td class="priority"><Badge :type="getPriorityBadgeType(ticket)" :text="ticket.priority.toUpperCase()"></Badge></td>
                <td>{{ticket.status}}</td>
                <td><fa @click="viewTicket(ticket.id)" class="fa-icon" icon="eye"></fa><fa @click="deleteTicket(ticket.id)" class="del-ticket" icon="xmark"></fa></td>
            </tr>
        </table>
        <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
    </div>
</template>

<script>
import Badge from '../../components/utils/Badge.vue';
import ConfirmDialog from '../../components/UI/ConfirmDialog.vue';
import Dropdown from '../../components/utils/Dropdown.vue';
const findCustomerName = require('../../scripts/customer/findCustomerById');
const findCompany = require('../../scripts/customer/findCompany');
const findCollaborator = require('../../scripts/collaborator/findUserNameById');
const _ = require('underscore');
const axios = require('axios');
export default {
    name: "Archived Tickets",
    data() {
        return {
            ticketList: [],
            ticketCpy: [],
            searchCpy: [],
            isSearched: false,
            isSorted: false,
            search: ''
        };
    },
    mounted() {
        axios.get(`${this.$store.state.BaseUrl}/tickets/all`, {
            headers: {
                Authorization: `Bearer ${this.$store.state.Bearer}`
            }
        }).then(res => {
            this.ticketList = res.data.filter(ticket => ticket.status === 'OK');
            this.ticketCpy = res.data.filter(ticket => ticket.status === 'OK');
        }).catch(err => {
            console.log(err);
        });
    },
    components: { Badge, ConfirmDialog, Dropdown },
    methods: {
        getCollab(id) {
            return findCollaborator(id, this.$store.state.UserList);
        },
        searchArray(e) {
            let input = e.data;
            if (this.isSearched) {
                this.ticketList = this.searchCpy;
                this.isSearched = true;
            }
            this.searchCpy = this.ticketList;
            if (input === null) {
                this.search = this.search.slice(0, this.search.length - 1);
            } else {
                this.search = this.search + input;
            }
            this.isSearched = true;
            this.ticketList = this.ticketList.filter(ticket => ticket.title.includes(this.search));
        },
        sortTickets(type) {
            if (type === 'normal') {
                this.ticketList = this.ticketCpy;
                this.isSorted = false;
            }
            if (type === 'date') {
                if (this.isSorted) {
                    this.ticketList = this.ticketCpy;
                    this.isSorted = false;
                }
                this.ticketCpy = this.ticketList;
                this.ticketList = _.sortBy(this.ticketList, 'created_at');
                this.ticketList.reverse();
                this.isSorted = true;
            }
            if (type === 'status') {
                if (this.isSorted) {
                    this.ticketList = this.ticketCpy;
                    this.isSorted = false;
                }
                this.ticketCpy = this.ticketList;
                this.ticketList = _.sortBy(this.ticketList, 'status');
                this.ticketList.reverse();
                this.isSorted = true;
            }
            if (type === 'priority') {
                if (this.isSorted) {
                    this.ticketList = this.ticketCpy;
                    this.isSorted = false;
                }
                this.ticketCpy = this.ticketList;
                this.ticketList = _.sortBy(this.ticketList, 'priority');
                this.isSorted = true;
            }
            if (type === 'user') {
                if (this.isSorted) {
                    this.ticketList = this.ticketCpy;
                    this.isSorted = false;
                }
                this.ticketCpyticketCpy = this.ticketList;
                this.ticketList = _.sortBy(this.ticketList, 'collaborator_id');
                this.isSorted = true;
            }
            if (type === 'company') {
                if (this.isSorted) {
                    this.ticketList = this.ticketCpy;
                    this.isSorted = false;
                }
                this.ticketCpyticketCpy = this.ticketList;
                this.ticketList = _.sortBy(this.ticketList, 'customer_id');
                this.isSorted = true;
            }
        },
        formatDate(date) {
            var opt = {
                timeZone: "Europe/Paris",
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric"
            };
            return new Date(date).toLocaleDateString("fr-FR", opt);
        },
        viewTicket(id) {
            this.$router.push({ name: 'Ticket', params: {id: id} });
        },
        getCustomerName(id) {
            return findCompany(id, this.$store.state.CustomersList);
        },
        getPriorityBadgeType(ticket) {
            switch(ticket.priority) {
                case 'bas':
                    return 'success';
                case 'normal':
                    return 'info';
                case 'haute': 
                    return 'danger';
                default: 
                    return 'warning';
            }
        },
        async deleteTicket(id) {
            const ok = await this.$refs.confirmDialog.show({
                title: 'Supprimer le ticket',
                message: 'Êtes vous sûr de vouloir supprimer ce ticket ? Cette action est irréversible.',
                okButton: 'Supprimer',
            })
            if (ok) {
                axios.delete(`${this.$store.state.BaseUrl}/tickets/${id}`, {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.Bearer}`,
                    }
                }).then(res => {
                    this.$router.go();
                }).catch(err => {
                    console.log(err);
                    createToast({ title: "Erreur", description: "Erreur du serveur" }, { type: "danger", showIcon: true });
                })
            }
        },
    }
}
</script>

<style scoped>
table {
    width: 96%;
    margin: 2%;
}

.customers-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2%;
    border-bottom: 2px solid lightseagreen;
}

.add-button {
    cursor: pointer;
    color: white;
    background-color: lightseagreen;
    border: none;
    font-size: 30px;
    border-radius: 50%;
    padding: 5px 15px 5px 15px;
    margin-bottom: 10px;
}

.add-button:hover {
    opacity: 0.7;
}

.priority {
    display: flex;
    justify-content: center;
    align-items: center;
}
.del-ticket {
    margin-left: 10px;
    font-size: 20px;
}

.sort {
    cursor: pointer;
}
.view_tickets {
    cursor: pointer;
    padding: 5px;
    border-radius: 5px;
    background-color: lightseagreen;
    color: white;
    font-size: 15px;
    border: none;
}

.view_tickets:hover {
    opacity: 0.7;
}
</style>

findCollaboratorName = (id, UserList) => {
    var name = "";
    UserList.forEach(element => {
        if (element.id === id) {
            name = element.first_name + " " + element.name;
            return name;
        }
    });
    return name;
}

module.exports = findCollaboratorName;

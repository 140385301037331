<template>
    <div id="view-root">
        <Modal ref="billWallet">
          <WalletBilling :wId="wId"></WalletBilling>
          <button type="button" class="cancel-btn" @click="cancelBill()">Annuler</button>
        </Modal>
        <div class="customers-top">
            <h3>Liste des portefeuilles</h3>
            <div class="buttons">
                <button @click="sortArray" class="sort-button">Trier</button>
                <button @click="this.$router.push('/create-wallet')" class="add-button">+</button>
            </div>
        </div>
        <table>
            <tr>
                <th>Id</th>
                <th>Nom</th>
                <th>Montant</th>
                <th>Facturation groupée</th>
                <th>Facturer</th>
                <th>Actions</th>
            </tr>
            <tr v-for="wallet in wallets">
                <td>{{wallet.id}}</td>
                <td>{{wallet.name}}</td>
                <td><WalletAmount :id="wallet.id"></WalletAmount></td>
                <td>{{getGroupBill(wallet.group_payment)}}</td>
                <th><button type="button" @click="newBill(wallet.id)">Facturer</button></th>
                <td><fa icon="comments-dollar" @click="setGroupBill(wallet.id, wallet.group_payment)"></fa><fa @click="deleteWallet(wallet.id)" class="del-ticket" icon="xmark"></fa> <fa @click="$router.push('/wallets/' + wallet.id + '/' + wallet.name)" icon="pen-to-square"></fa></td>
            </tr>
        </table>
        <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
    </div>
</template>

<script>
const _ = require('underscore');
import WalletAmount from '../components/WalletAmount.vue';
import ConfirmDialog from '../components/UI/ConfirmDialog.vue';
import Modal from '../components/utils/Modal.vue';
import WalletBilling from '../components/WalletBilling.vue';
const axios = require('axios');
export default {
    name: "Wallet",
    data() {
        return {
            wallets: [],
            cpyWallet: [],
            isSorted: false,
            wId: 0,
        };
    },
    methods: {
        cancelBill() {
            this.$refs.billWallet.close();
        },
        newBill(customerId) {
            this.$refs.billWallet.open();
            this.wId = customerId;
        },
        renameWallet() {
            this.$refs.popupWallet.open();
        },
        cancelRename() {
            this.$refs.popupWallet.close();
        },
        sortArray() {
            if (this.isSorted) {
                this.wallets = this.cpyWallet;
                this.isSorted = false;
                return;
            }
            this.cpyWallet = this.wallets;
            this.wallets = _.sortBy(this.wallets, 'name'); 
            this.isSorted = true;
        },
        getGroupBill(status) {
            if (status === 0) {
                return 'Non';
            } else {
                return 'Oui';
            }
        },
        setGroupBill(id, status) {
            let value = true;
            if (status === 1) {
                value = false;
            }
            axios.post(`${this.$store.state.BaseUrl}/wallets/${id}/group-payment`, {
                value: value
            },{
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                this.$router.go();
            }).catch(err => {
                console.log(err);
            })
        },
        getAllWallets() {
            axios.get(`${this.$store.state.BaseUrl}/wallets/all`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                this.wallets = res.data;
                this.$store.commit("setWalletList", res.data);
            }).catch(err => {
                console.log(err);
            });
        },
        async deleteWallet(id) {
            const ok = await this.$refs.confirmDialog.show({
                title: 'Supprimer le portefeuille',
                message: 'Êtes vous sûr de vouloir supprimer ce portefeuille ? Cette action est irréversible.',
                okButton: 'Supprimer',
            })
            if (ok) {
                axios.delete(`${this.$store.state.BaseUrl}/wallets/${id}`, {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.Bearer}`,
                    }
                }).then(res => {
                    this.$router.go();
                }).catch(err => {
                    console.log(err);
                    createToast({ title: "Erreur", description: "Erreur du serveur" }, { type: "danger", showIcon: true });
                })
            }
        },
    },
    mounted() {
        this.getAllWallets();
    },
    components: { WalletAmount, ConfirmDialog, Modal, WalletBilling }
}
</script>

<style scoped>
table {
    width: 100%;
    margin: 2%;
}

.customers-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2%;
    border-bottom: 2px solid lightseagreen;
}

.add-button {
    cursor: pointer;
    color: white;
    background-color: lightseagreen;
    border: none;
    font-size: 30px;
    border-radius: 50%;
    padding: 5px 15px 5px 15px;
    margin-bottom: 10px;
}
.del-ticket {
    margin-left: 10px;
    font-size: 20px;
}

.add-button:hover {
    opacity: 0.7;
}

.sort-button {
    cursor: pointer;
    color: white;
    background-color: lightseagreen;
    border: none;
    font-size: 20px;
    border-radius: 10px;
    padding: 5px 15px 5px 15px;
    margin-bottom: 10px;
    margin-inline: 10px;
}

.sort-button:hover {
    color: lightseagreen;
    background-color: white;
    border: 1px solid lightseagreen;
}
</style>

<template>
  <div id="customers-root">
    <div class="customers-top">
        <h3>Liste des clients</h3>
        <button class="import-btn" @click="exportCustomers()">Exporter</button>
        <Dropdown class="sort" :buttonText="'Trier par'">
                    <ul>
                        <li v-if="isSorted" @click="sortCustomers('normal')">Annuler le tri</li>
                        <li @click="sortCustomers('date')">Date de création</li>
                        <li @click="sortCustomers('alpha')">A-Z</li>
                        <li @click="sortCustomers('wallet')">Portefeuille</li>
                        <li @click="sortCustomers('user')">Collaborateur</li>
                    </ul>
                </Dropdown>
        <input type="search" @input="searchArray" placeholder="Chercher un client...">
        <button @click="this.$router.push('/create-customer')" class="add-button">+</button>
    </div>
    <div id="customer-table">
        <span>Total : {{totalList}}</span>
        <table>
            <tr>
                <th>Id</th>
                <th>Nom</th>
                <th>Portefeuille</th>
                <th>Collaborateur</th>
                <th>Actions</th>
            </tr>
            <tr v-for="(customer, idx) in Customers" class="infoCustomers">
                <td>{{customer.id}}</td>
                <td v-on:click="goToProfil(customer.id)">{{customer.company}}</td>
                <td>{{getWallet(customer.wallet_id)}}</td>
                <td>{{getName(customer.collaborator_id)}}</td>
                <td><fa v-on:click="goToProfil(customer.id)" class="fa-icon" icon="eye"></fa><fa class="fa-icon" icon="bell"></fa></td>
            </tr>
        </table>
    </div>
    
    </div>
</template>

<script>
import Dropdown from '../components/utils/Dropdown.vue';
const _ = require('underscore');
const axios = require('axios');
const findCollaboratorName = require('../scripts/collaborator/findUserNameById');
const findWalletName = require('../scripts/wallets/fileWalletNameById');

export default {
    data() {
        return {
            Customers: [],
            customersCpy: [],
            isSorted: false,
            customersSearch: [],
            isSearched: false,
            search: ''
        };
    },
    computed: {
        totalList() {
            return this.Customers.length;
        }      
    },
    methods: {
        exportCustomers() {
          axios.get(`${this.$store.state.BaseUrl}/customers/export`, {
            responseType: 'blob',
            headers: {
              'Authorization': `Bearer ${this.$store.state.Bearer}`
            }
          }).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]), {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Clients.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove();
          }).catch(err => {
            console.log(err);
          })
        },
        searchArray(e) {
            let input = e.data;
            if (this.isSearched) {
                this.Customers = this.customersSearch;
                this.isSearched = true;
            }
            this.customersSearch = this.Customers;
            if (input === null) {
                this.search = this.search.slice(0, this.search.length - 1);
            } else {
                this.search = this.search + input;
            }
            this.isSearched = true;
            this.Customers = this.Customers.filter(customer => customer.company.toUpperCase().includes(this.search.toUpperCase()));
        },
        sortCustomers(type) {
            if (type === 'normal') {
                this.Customers = this.customersCpy;
                this.isSorted = false;
            }
            if (type === 'date') {
                if (this.isSorted) {
                    this.Customers = this.customersCpy;
                    this.isSorted = false;
                }
                this.customersCpy = this.Customers;
                this.Customers = _.sortBy(this.Customers, 'created_at');
                this.Customers.reverse();
                this.isSorted = true;
            }
            if (type === 'alpha') {
                if (this.isSorted) {
                    this.Customers = this.customersCpy;
                    this.isSorted = false;
                }
                this.customersCpy = this.Customers;
                this.Customers = _.sortBy(this.Customers, 'company');
                this.isSorted = true;
            }
            if (type === 'wallet') {
                if (this.isSorted) {
                    this.Customers = this.customersCpy;
                    this.isSorted = false;
                }
                this.customersCpy = this.Customers;
                this.Customers = _.sortBy(this.Customers, 'wallet_id');
                this.isSorted = true;
            }
            if (type === 'user') {
                if (this.isSorted) {
                    this.Customers = this.customersCpy;
                    this.isSorted = false;
                }
                this.customersCpy = this.Customers;
                this.Customers = _.sortBy(this.Customers, 'collaborator_id');
                this.isSorted = true;
            }
        },
        getWallet(id) {
            return findWalletName(id, this.$store.state.WalletList);
        },
        getName(id) {
            return findCollaboratorName(id, this.$store.state.UserList);
        },
        goToProfil(id) {
            this.$router.push({ name: "Customer", params: { id: id } });
        },
        getCustomersList() {
            axios.get(`${this.$store.state.BaseUrl}/customers/all`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(result => {
                this.Customers = result.data;
                this.$store.commit("setCustomersList", result.data);
            }).catch(err => {
                console.log(err);
            });
        }
    },
    mounted() {
        this.getCustomersList();
    },
    components: { Dropdown }
}
</script>

<style scoped>

.customers-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2%;
    border-bottom: 2px solid lightseagreen;
}

.import-btn {
    cursor: pointer;
    color: white;
    background-color: lightseagreen;
    border: none;
    font-size: 15px;
    border-radius: 5px;
    padding: 5px 15px 5px 15px;
}

.import-btn:hover {
    opacity: 0.7;
}

.add-button {
    cursor: pointer;
    color: white;
    background-color: lightseagreen;
    border: none;
    font-size: 30px;
    border-radius: 50%;
    padding: 5px 15px 5px 15px;
    margin-bottom: 10px;
}

.add-button:hover {
    opacity: 0.7;
}

#customer-table {

    margin: 2%;
    width: 100%;
}

table, td {
  border-bottom: 1px solid black;
  border-collapse: collapse;
}

table {
    width: 100%;
    background-color: whitesmoke;
    border-radius : 10px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    transition: 0.3s;
}

.infoCustomers {
    border: 1px solid black;
}

.fa-icon {
    margin-left: 10px;
}
.sort-button {
    cursor: pointer;
    color: white;
    background-color: lightseagreen;
    border: none;
    font-size: 20px;
    border-radius: 10px;
    padding: 5px 15px 5px 15px;
    margin-bottom: 10px;
    margin-inline: 10px;
}

.sort-button:hover {
    color: lightseagreen;
    background-color: white;
    border: 1px solid lightseagreen;
}
</style>

<template>
    <div id="view-root">
        <div id="bill-top">
            <h3>Facturer</h3>
            <span>Total Période : {{bigTotal}}€  | Total sélectionné : {{total}}€</span>
            <div id="bill-bar">
              <Dropdown class="sort" :buttonText="'Trier par'">
                    <ul>
                        <li v-if="isSorted" @click="sortBill('normal')">Annuler le tri</li>
                        <li @click="sortBill('alpha')">A-Z</li>
                        <li @click="sortBill('wallet')">Portefeuille</li>
                        <li @click="sortBill('status')">Statut</li>
                    </ul>
                </Dropdown>
              <button type="button" @click="selectAll">Tout sélectionner</button>
              <input type="number" v-model="mandatId" placeholder="Mandat ID (ex 127)"/>
              <button type="button" @click="exportBill">Confirmer</button>
            </div>
        </div>
        <div id="bill">
            <table>
                <tr>
                    <th>Date</th>
                    <th>Client</th>
                    <th>Portefeuille</th>
                    <th>Produit</th>
                    <th>Quantité</th>
                    <th>Montant (€)</th>
                    <th>Statut</th>
                    <th>Facturer</th>
                </tr>
                <tr v-for="bill in billList">
                    <td>{{formatDate(bill.order_date)}}</td>
                    <td>{{getName(bill.customer_id)}}</td>
                    <td>{{getWallet(bill.wallet_id)}}</td>
                    <td>{{bill.product_name}}</td>
                    <td>{{bill.quantity}}</td>
                    <td>{{getAmount(bill.product_price, bill.quantity)}}</td>
                    <td>{{bill.status}}</td>
                    <td><input type="checkbox" @input="handleCheckBox($event, bill.id, getAmount(bill.product_price, bill.quantity))"></td>
                </tr>
                <span v-if="!billList.length" class="error-message">Aucun paiement pour cette période</span>
            </table>
        </div>
    </div>
</template>

<script>
const findCustomer = require('../../scripts/customer/findCompany');
const _ = require('underscore');
const findWalletName = require('../../scripts/wallets/fileWalletNameById');
import Dropdown from '../../components/utils/Dropdown.vue';
const axios = require('axios');
export default {
    name: 'Export Billing',
    data() {
        return {
            billList: [],
            billsToExport: [],
            bill_cpy: [],
            isSorted: false,
            startDate: '',
            endDate: '',
            total: 0,
            bigTotal: 0,
            allSelected: false,
            mandatId: null,
        }
    },
    components: { Dropdown },
    methods: {
        selectAll() {
          if (!this.allSelected) {
            for (let bill in this.billList) {
              this.billsToExport.push(this.billList[bill].id);
              this.total += this.billList[bill].product_price * this.billList[bill].quantity;
              this.allSelected = true;
            }
          } else {
            this.billsToExport = [];
            this.total = 0;
            this.allSelected = false;
          }
        },
        handleCheckBox (e, id, price) {
          if (e.target.checked) {
            this.billsToExport.push(id);
            this.total += price;
          } else {
            this.billsToExport = this.billsToExport.filter(bill => bill !== id);
            this.total -= price;
          }
        },
        exportBill() {
          if (!this.billsToExport.length || !this.mandatId) {
            return;
          }
          axios.post(`${this.$store.state.BaseUrl}/billing/export`, {
            ids: this.billsToExport
          }, {
            headers: {
              Authorization: `Bearer ${this.$store.state.Bearer}`
            }
          }).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]), {type: 'text/csv'});
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Facturation_${this.startDate}-${this.endDate}.csv`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            const mandatId = this.mandatId;
            axios.post(`${this.$store.state.BaseUrl}/mandat/create/${mandatId}`, {
              ids: this.billsToExport,
            }, {
              headers: {
                Authorization: `Bearer ${this.$store.state.Bearer}`
              }
            }).then(sepa => {
              const url = window.URL.createObjectURL(new Blob([sepa.data]), {type: 'text/xml'});
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${mandatId}.xml`);
              document.body.appendChild(link);
              link.click();
              link.remove();
            }).catch(err => {
              console.log(err);
            })
          }).catch(err => {
            console.log(err);
          })
        },
        sortBill(type) {
            if (type === 'normal') {
                this.billList = this.bill_cpy;
                this.isSorted = false;
            }
            if (type === 'alpha') {
                if (this.isSorted) {
                    this.billList = this.bill_cpy;
                    this.isSorted = false;
                }
                this.bill_cpy = this.billList;
                this.billList = _.sortBy(this.billList, 'customer_id');
                this.isSorted = true;
            }
            if (type === 'wallet') {
                if (this.isSorted) {
                    this.billList = this.bill_cpy;
                    this.isSorted = false;
                }
                this.bill_cpy = this.billList;
                this.billList = _.sortBy(this.billList, 'wallet_id');
                this.isSorted = true;
            }
            if (type === 'status') {
                if (this.isSorted) {
                    this.billList = this.bill_cpy;
                    this.isSorted = false;
                }
                this.bill_cpy = this.billList;
                this.billList = _.sortBy(this.billList, 'status');
                this.isSorted = true;
            }
        },
        formatDate(date) {
            var opt = {
                timeZone: "Europe/Paris",
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric"
            };
            return new Date(date).toLocaleDateString("fr-FR", opt);
        },
        updateDate() {
          var date = new Date();
          var year = date.getFullYear();
          var s_month = this.periods[this.period_idx].month_code;
          var e_month = this.periods[this.period_idx + 1].month_code;
          this.startDate = year + '-' + s_month + '-01';
          this.endDate = year + '-' + e_month + '-01';
          this.fetchAllBills();
        },
        getCurrentMonth() {
            var date = new Date();
            var month = date.getMonth();
            return month;
        },
        getFullAmount() {
            this.billList.forEach(bill => {
                this.bigTotal += bill.product_price * bill.quantity;
            });
        },
        getAmount(price, quantity) {
            return price * quantity;
        },
        getWallet(id) {
            return findWalletName(id, this.$store.state.WalletList);
        },
        getName(id) {
            return findCustomer(id, this.$store.state.CustomersList);
        },
        async deleteBill(id) {
            const ok = await this.$refs.confirmDialog.show({
                title: 'Supprimer cette facturation',
                message: 'Êtes vous sûr de vouloir supprimer cette facturation ? Cette action est irréversible.',
                okButton: 'Supprimer',
            })
            if (ok) {
                axios.delete(`${this.$store.state.BaseUrl}/billing/${id}`, {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.Bearer}`,
                    }
                }).then(res => {
                    this.$router.go();
                }).catch(err => {
                    console.log(err);
                    createToast({ title: "Erreur", description: "Erreur du serveur" }, { type: "danger", showIcon: true });
                })
            }
        },
        fetchAllBills() {
            axios.get(`${this.$store.state.BaseUrl}/billing/range?startDate=${this.startDate}&endDate=${this.endDate}`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                this.billList = res.data.reverse();
                this.getFullAmount();
            }).catch(err => {
                console.log(err);
            })
        },
    },
    mounted() {
        if (!this.$route.params.startDate || !this.$route.params.endDate) {
            this.$router.push("/");
        }
        else {
          this.startDate = this.$route.params.startDate;
          this.endDate = this.$route.params.endDate;
        }
        this.fetchAllBills();
    }
}
</script>

<style scoped>
table {
    margin-top: 10px;
    width: 100%;
}

#bill-top {
    padding: 5px;
    margin-inline: 2%;
    border-bottom: 2px solid lightseagreen;
}

.error-message {
  text-align: center;
}

#bill-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.del-bill {
    margin-left: 10px;
    font-size: 20px;
}
</style>

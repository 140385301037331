<template>
    <div id="view-root">
        <h2>Tâches pour {{customer.company}}</h2>
        <div id="tasks-tool">
            <div class="task-tool">
                <span class="task-text">Afficher les tâches effectuées</span>
                <label class="switch">
                    <input type="checkbox" v-model="viewComplete" @change="checkBtn">
                    <span class="slider round"></span>
                </label>
            </div>
            <div class="task-tool">
                <span class="task-text">Afficher les tâches pour la période :</span>
                <select v-model="currentMonth" @change="updateDate()">
                  <option v-for="period in periods" :value="period.idx">{{period.name}}</option>
                </select>
              </div>
        </div>
        <div id="tasks-container">
            <div class="card task" v-for="task in tasks" :style="'border-left: 3px solid ' + task.color">
                <div class="card-body">
                    <h3>{{task.name}}</h3>
                    <table>
                        <tr>
                            <td>Nom: </td>
                            <td>{{task.name}}</td>
                        </tr>
                        <tr>
                            <td>Date: </td>
                            <td>{{formatDate(task.due_date)}}</td>
                        </tr>
                        <tr>
                            <td>Effectué: </td>
                            <td>{{completion(task.completed)}}</td>
                        </tr>
                    </table>
                </div>
                <div class="card-actions">
                    <button type="button" @click="setTaskAsComplete(task.id)">Marquer comme effectué</button>
                    <button type="button" @click="$router.push('/tasks/edit/' + task.id)">Modifier tâche</button>
                    <button type="button" @click="deleteTask(task.name)">Supprimer la tâche</button>
                </div>
            </div>
        </div>
        <router-link :to="('/customers/' + customer.id)" class="backlink">Retour à {{customer.company}}</router-link>
        <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
    </div>
</template>

<script>
import ConfirmDialog from '../../components/UI/ConfirmDialog.vue';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
const axios = require('axios');
const moment = require('moment');
export default {
    name: "View task",
    data() {
        return {
            customer: {},
            tasks: [],
            tasksList: [],
            unCompletedTasks: [],
            currentMonth: 0,
            viewComplete: true,
            periods: [
              {
                idx: 0,
                name: 'Janvier',
                month_code: '01',
              },
              {
                idx: 1,
                name: 'Février',
                month_code: '02',
              },
              {
                idx: 2,
                name: 'Mars',
                month_code: '03',
              },
              {
                idx: 3,
                name: 'Avril',
                month_code: '04',
              },
              {
                idx: 4,
                name: 'Mai',
                month_code: '05',
              },
              {
                idx: 5,
                name: 'Juin',
                month_code: '06',
              },
              {
                idx: 6,
                name: 'Juillet',
                month_code: '07',
              },
              {
                idx: 7,
                name: 'Août',
                month_code: '08',
              },
              {
                idx: 8,
                name: 'Septembre',
                month_code: '09',
              },
              {
                idx: 9,
                name: 'Octobre',
                month_code: '10',
              },
              {
                idx: 10,
                name: 'Novembre',
                month_code: '11',
              },
              {
                idx: 11,
                name: 'Décembre',
                month_code: '12',
              },
            ],
        };
    },
    computed: {},
    methods: {
        getUncompletedTasks() {
            this.unCompletedTasks = this.tasks.filter(task => task.isComplete === false);
        },
        checkBtn(e) {
          
        },
        setTaskAsComplete(taskId) {
            axios.get(`${this.$store.state.BaseUrl}/tasks/${taskId}/complete`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => this.$router.go()).catch(err => console.log(err));
        },
        async deleteTask(name) {
            const ok = await this.$refs.confirmDialog.show({
                title: "Supprimer la tâche",
                message: "Êtes vous sûr de vouloir supprimer cette tâche ? Cette action est irréversible.",
                okButton: "Supprimer",
            });
            if (ok) {
                axios.delete(`${this.$store.state.BaseUrl}/tasks/${name}`, {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.Bearer}`,
                    }
                }).then(res => {
                    createToast({ title: "Tâche supprimé !" }, { type: "success", showIcon: true, onClose: this.$router.push("/tasks") });
                    this.$router.go();
                }).catch(err => {
                    console.log(err);
                    createToast({ title: "Erreur", description: "Erreur du serveur" }, { type: "danger", showIcon: true });
                });
            }
        },
        completion(status) {
            if (status === 0) {
                return "Non";
            }
            return "Oui";
        },
        parseTasks() {
            if (!this.customer.tasks) {
                return;
            }
            this.tasksList = this.customer.tasks;
            this.tasks = this.tasksList[this.currentMonth].tasks;
        },
        fetchTasks(id, month, year) {
            axios.get(`${this.$store.state.BaseUrl}/tasks/${id}/${month}/${year}`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                this.tasks = res.data;
            }).catch(err => console.log(err));
        },
        getUser(id) {
            axios.get(`${this.$store.state.BaseUrl}/customers/${id}`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`,
                }
            }).then(res => {
                this.customer = res.data[0];
                let m = this.periods[this.currentMonth].month_code;
                let y = new Date().getFullYear();
                this.fetchTasks(this.customer.id, m, y);
            }).catch(err => {
                console.log(err);
            });
        },
        updateDate() {
          let m = this.periods[this.currentMonth].month_code;
          let y = new Date().getFullYear();
          this.fetchTasks(this.customer.id, m, y);
        },
        formatDate(date) {
            return moment(date).format("DD/MM/YYYY");
        },
    },
    mounted() {
        if (!this.$route.params.id) {
            this.$router.push("/");
        }
        else {
            this.currentMonth = new Date().getMonth();
            this.getUser(this.$route.params.id);
        }
    },
    components: { ConfirmDialog }
}
</script>

<style>

#tasks-container {
    display: flex;
    flex-wrap: wrap;
}

.task {
    width: 35%;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
}

.card-body {
    padding: 10px;
    margin-inline: 20px;
    width: 40%;
}

.card-actions {
    padding: 10px;
    margin-inline: 20px;
    display: flex;
    flex-direction: column;
}

.card-actions button {
    margin-block: 10px;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    padding: 10px;
    height: 30px;
    background-color: lightseagreen;
    color: white;
}

.card-actions button:hover {
    background-color: white;
    color: lightseagreen;
    border: 1px solid lightseagreen;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.task-tool {
    display: flex;
    justify-content: center;
    align-items: center;
}

.task-text {
    margin-inline: 20px;
}

.backlink {
    margin-block: 10px;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    padding: 10px;
    height: 30px;
    background-color: lightseagreen;
    color: white;
}
</style>

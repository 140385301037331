<template>
    <div id="view-root">
        <div class="customers-top">
        <h3>Liste des collaborateurs</h3>
        <button @click="this.$router.push('/register')" class="add-button">+</button>
    </div>
    <div id="customer-table">
        <table>
            <tr>
                <th>Id</th>
                <th>Nom</th>
                <th>Email</th>
                <th>Administrateur</th>
                <th>Double Authentification</th>
                <th>Actions</th>
            </tr>
            <tr v-for="user in usersList">
                <td>{{user.id}}</td>
                <td>{{user.first_name}} {{user.name}}</td>
                <td>{{user.email}}</td>
                <td>{{getBool(user.is_admin)}}</td>
                <td>{{getBool(user['2fa_valid'])}}</td>
                <td><fa icon="xmark" @click="DeleteUser(user.id)"></fa></td>
            </tr>
        </table>
    </div>
    <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
    </div>
</template>

<script>
import ConfirmDialog from '../../components/UI/ConfirmDialog.vue';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
const axios = require('axios');
export default {
    name: "Collaborators",
    data() {
        return {
            usersList: []
        };
    },
    methods: {
        getBool(status) {
            if (status === 0) {
                return "Non";
            }
            else {
                return "Oui";
            }
        },
        fetchCollaborators() {
            axios.get(`${this.$store.state.BaseUrl}/user/list/all`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                this.usersList = res.data;
            }).catch(err => {
                console.log(err);
            });
        },
        async DeleteUser(id) {
            const ok = await this.$refs.confirmDialog.show({
                title: 'Supprimer le collaborateur',
                message: 'Êtes vous sûr de vouloir supprimer ce collaborateur ? Cette action est irréversible.',
                okButton: 'Supprimer',
            })
            if (ok) {
                axios.delete(`${this.$store.state.BaseUrl}/user/${id}`, {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.Bearer}`,
                    }
                }).then(res => {
                    createToast({ title: "Collaborateur supprimé !" }, { type: "success", showIcon: true, onClose: this.$router.go() });
                }).catch(err => {
                    console.log(err);
                    createToast({ title: "Erreur", description: "Erreur du serveur" }, { type: "danger", showIcon: true });
                })
            }
        },
    },
    mounted() {
        this.fetchCollaborators();
    },
    components: { ConfirmDialog }
}
</script>

<style scoped>

.customers-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2%;
    border-bottom: 2px solid lightseagreen;
}

.add-button {
    cursor: pointer;
    color: white;
    background-color: lightseagreen;
    border: none;
    font-size: 30px;
    border-radius: 50%;
    padding: 5px 15px 5px 15px;
    margin-bottom: 10px;
}

.add-button:hover {
    opacity: 0.7;
}

#customer-table {

    margin: 2%;
    width: 100%;
}

table {
    width: 100%;
}

.fa-icon {
    margin-left: 10px;
}
</style>

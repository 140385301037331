<template>
    <div id="component-root">
        Edit task
    </div>
</template>

<script>
    const axios = require('axios');
export default {
    name: 'Edit Task',
    props: {
        customerId: {
            required: true
        }
    },
    data() {
        return {

        }
    },
    methods: {
        
    },
    mounted() {

    },
    component: {}
}
</script>

<style scoped>

</style>

<template>
    <div id="component-root">
        <select name="collab" id="select-collab" @change="onInput">
            <option v-for="(user, k) in UserList" v-bind:key="k" :value="user.id" :selected="user.id === userId">{{user.first_name}} {{user.name}}</option>
        </select>
    </div>
</template>

<script>
export default {
    name: "select-user",
    props: {
        UserList: {
            type: Array,
            required: true
        },
        UserId: {
            type: Number,
            required: true
        }
    },
    methods: {
        onInput(e) {
            this.$emit('on-collab', e.target.value)
        }
    }
}
</script>

<style scoped>
select {
    border-radius: 10px;
    height: 35px;
    width: 100%;
    text-align: center;
}

select:focus {
    outline: none;
    border: 1px solid lightseagreen;
}

::placeholder {
    text-align: center; 
}

/* or, for legacy browsers */
::-webkit-input-placeholder {
    text-align: center;
}

:-moz-placeholder { /* Firefox 18- */
    text-align: center;  
}

::-moz-placeholder {  /* Firefox 19+ */
    text-align: center;  
}

:-ms-input-placeholder {  
    text-align: center; 
}
</style>

<template>
  <div class="root" v-if="loaded">
    <h1>Modifier Tâche</h1>
    <label>Titre de la tâche</label><br>
    <input type="text" placeholder="Titre de la tâche" v-model="task.name">
    <br><br>
    <label>Date (jour)</label><br>
    <input type="datetime-local" id="taskTime" name="taskTime" v-model="task.due_date">
    <br><br>
    <button type="button" class="ok-btn" @click="updateTask()">Modifier</button>
  </div>
</template>

<script>
const axios = require("axios");

export default {
  name: "EditTask",
  data() {
    return {
      task: {},
      loaded: false,
    };
  },
  methods: {
    updateTask() {
      if (!this.task.name || !this.task.due_date) {
        return;
      }
      axios
        .put(
          `${this.$store.state.BaseUrl}/tasks/${this.$route.params.id}`,
          {
            name: this.task.name,
            due_date: this.task.due_date,
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.Bearer}`,
            },
          }
        )
        .then((res) => {
          this.$router.push('/tasks');
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    axios
      .get(`${this.$store.state.BaseUrl}/tasks/${this.$route.params.id}`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.Bearer}`,
        },
      })
      .then((res) => {
        this.task = res.data;
        this.loaded = true;
      })
      .catch((err) => {
        console.log(err);
      });
  },
}
</script>

<style>

</style>

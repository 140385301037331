<template>
    <div id="emails">
        <div id="emails-top" v-bind:style = "noemail ? 'justify-content: space-between;' : 'justify-content: flex-end;'">
            <span v-if="noemail">Pas d'emails reçus</span>
            <a v-bind:href="'mailto:'+ email + '?bcc=social@g-paie.net'"><button>Envoyer un email</button></a>
        </div>
        <table v-if="noemail === false">
            <tr>
                <th>Date</th>
                <th>Sujet</th>
                <th>Actions</th>
            </tr>
            <tr v-for="em in Emails">
                <td>Now</td>
                <td>{{em.subject}}</td>
                <td class="click"><fa @click="view(em.textAsHtml)" icon="eye"></fa></td>
            </tr>
        </table>
        <EmailView ref="emailPopup"></EmailView>
    </div>
</template>

<script>
const axios = require('axios');
import EmailView from './EmailView.vue';

export default {
    name: "email",
    data() {
        return {
            Emails: [],
            noemail: false
        }
    },
    props: {
        email: {
            required: true
        }
    },
    methods: {
        async view(html) {
            await this.$refs.emailPopup.viewEmail({
                html: html
            })
        },
        fetchEmails() {
            axios.get(`${this.$store.state.BaseUrl}/customers/${this.email}/emails`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`,
                }
            }).then(res => {
                if (res.status == 200) {
                    this.Emails = res.data;
                    if (this.Emails.length === 0) {
                        this.noemail = true;
                    }  
                }
            }).catch(err => {
                console.log(err);
            });
        }
    },
    components: { EmailView },
    mounted() {
        this.fetchEmails();
    }
}
</script>

<style>

#emails {
    padding-bottom: 20px;
}

.click {
    cursor: pointer;
}

table {
    width: 100%;
}

tr {
    height: 20px;
}

#emails-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#emails-top span {
    margin-left: 30px;
}

#emails-top a {
    margin-right: 30px;
    width: 30%;
}

#emails-top button {
    cursor: pointer;
    width: 100%;
    border-radius: 10px;
    border: none;
    height: 30px;
    background-color: lightseagreen;
    color: white;
}

#emails-top button:hover {
    background-color: white;
    color: lightseagreen;
    border: 1px solid lightseagreen;
}

</style>

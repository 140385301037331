<template>
  <div id="view-root">
        <div class="customers-top">
            <h3>Liste des Mandats SEPA</h3>
            <div class="buttons">
                <button @click="sortArray()" class="sort-button">Trier</button>
                <button type="button" class="sort-button">Exporter</button>
                <button @click="this.$router.push('/mandats/create')" class="add-button">+</button>
            </div>
        </div>
        <table>
            <tr>
                <th>Id</th>
                <th>Entreprise</th>
                <th>RUM</th>
                <th>Date</th>
                <th>Actif</th>
                <th>Actions</th>
            </tr>
            <tr v-for="mandat in mandats">
                <td>{{mandat.id}}</td>
                <td>{{mandat.debitor_name}}</td>
                <td>{{mandat.rum}}</td>
                <td>{{mandat.created_at}}</td>
                <td>{{completion(mandat.active)}}</td>
                <td><fa @click="deleteMandat(mandat.id)" class="del-ticket" icon="xmark"></fa> <fa @click="$router.push('/mandats/' + mandat.id)" icon="eye"></fa></td>
            </tr>
        </table>
        <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
    </div>
</template>

<script>
import axios from 'axios';
import _ from 'underscore'; 
export default {
  name: 'Mandats',
  data() {
    return {
      mandats: [],
      cpyMandats: [],
      isSorted: false
    }
  },
  methods: {
    completion(status) {
            if (status === 0) {
                return "Non";
            }
            return "Oui";
        },
    sortArray() {
      if (this.isSorted) {
        this.mandats = this.cpyMandats;
        this.isSorted = false;
        return;
      }
      this.cpyMandats = this.mandats;
      this.mandats = _.sortBy(this.mandats, 'debitor_name');
      this.isSorted = true;
    },
    deleteMandat(id) {
      this.$refs.confirmDialog.open('Voulez-vous vraiment supprimer ce mandat ?', () => {
        axios.delete('/api/mandats/' + id)
          .then(() => {
            this.mandats = this.mandats.filter(mandat => mandat.id !== id);
          })
          .catch(err => {
            console.log(err);
          });
      });
    },
    getMandats() {
      axios.get(`${this.$store.state.BaseUrl}/mandat/`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.Bearer}`
        }
      })
        .then(res => {
          this.mandats = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  mounted() {
    this.getMandats();
  }

}
</script>

<style>

table {
    width: 100%;
    margin: 2%;
}

.customers-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2%;
    border-bottom: 2px solid lightseagreen;
}

.add-button {
    cursor: pointer;
    color: white;
    background-color: lightseagreen;
    border: none;
    font-size: 30px;
    border-radius: 50%;
    padding: 5px 15px 5px 15px;
    margin-bottom: 10px;
}
.del-ticket {
    margin-left: 10px;
    font-size: 20px;
}

.add-button:hover {
    opacity: 0.7;
}

.sort-button {
    cursor: pointer;
    color: white;
    background-color: lightseagreen;
    border: none;
    font-size: 20px;
    border-radius: 10px;
    padding: 5px 15px 5px 15px;
    margin-bottom: 10px;
    margin-inline: 10px;
}

.sort-button:hover {
    color: lightseagreen;
    background-color: white;
    border: 1px solid lightseagreen;
}

</style>

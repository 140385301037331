<template>
    <div id="component-root">
        <select name="wallet" id="select-wallet" @change="onInput">
            <option v-for="(wallet, k) in walletList" v-bind:key="k" :value="wallet.id" :selected="wallet.id === walletId">{{wallet.name}}</option>
        </select>
    </div>
</template>

<script>
export default {
    name: 'Select Wallet',
    props: {
        walletList: {
            required: true,
            type: Array
        },
        walletId: {
          required: true,
          type: Number
        }
    },
    methods: {
        onInput(e) {
            this.$emit('on-wallet', e.target.value)
        }
    },
}
</script>

<style scoped>
select {
    border-radius: 10px;
    height: 35px;
    width: 100%;
    text-align: center;
}

select:focus {
    outline: none;
    border: 1px solid lightseagreen;
}

::placeholder {
    text-align: center; 
}

/* or, for legacy browsers */
::-webkit-input-placeholder {
    text-align: center;
}

:-moz-placeholder { /* Firefox 18- */
    text-align: center;  
}

::-moz-placeholder {  /* Firefox 19+ */
    text-align: center;  
}

:-ms-input-placeholder {  
    text-align: center; 
}
</style>

<template>
    <div id="view-root">
        <div class="customers-top">
            <h3>Liste des tickets de développement</h3>
            <button @click="this.$router.push('/dev/create')" class="add-button">+</button>
        </div>
        <table>
            <tr>
                <th>Id</th>
                <th>Titre</th>
                <th>Date</th>
                <th>Statut</th>
                <th>Priorité</th>
                <th>Actions</th>
            </tr>
            <tr v-for="ticket in ticketsList">
                <td>{{ticket.id}}</td>
                <td>{{ticket.title}}</td>
                <td>{{formatDate(ticket.created_at)}}</td>
                <td>{{ticket.status}}</td>
                <td class="priority"><Badge :type="getPriorityBadgeType(ticket)" :text="ticket.priority.toUpperCase()"></Badge></td>
                <td><fa @click="viewTicket(ticket.id)" icon="eye"></fa><fa class="del-ticket" icon="xmark"></fa></td>
            </tr>
        </table>
    </div>
</template>

<script>
import Badge from '../../components/utils/Badge.vue';
const axios = require('axios');
export default {
    name: "Dev Tickets",
    data() {
        return {
            ticketsList: []
        };
    },
    methods: {
        viewTicket(id) {
            this.$router.push({name: 'Dev Ticket', params: {
                id: id
            }});
        },
        getPriorityBadgeType(ticket) {
            switch(ticket.priority) {
                case 'bas':
                    return 'success';
                case 'normal':
                    return 'info';
                case 'haute': 
                    return 'danger';
                default: 
                    return 'warning';
            }
        },
        formatDate(date) {
            var opt = {
                timeZone: "Europe/Paris",
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric"
            };
            return new Date(date).toLocaleDateString("fr-FR", opt);
        },
        fetchTickets() {
            axios.get(`${this.$store.state.BaseUrl}/dev/all`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                this.ticketsList = res.data;
            }).catch(err => {
                console.log(err);
            });
        },
    },
    mounted() {
        this.fetchTickets();
    },
    components: { Badge }
}
</script>

<style scoped>
table {
    width: 100%;
    margin: 2%;
    background-color: whitesmoke;
}

.customers-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2%;
    border-bottom: 2px solid lightseagreen;
}

.add-button {
    cursor: pointer;
    color: white;
    background-color: lightseagreen;
    border: none;
    font-size: 30px;
    border-radius: 50%;
    padding: 5px 15px 5px 15px;
    margin-bottom: 10px;
}

.add-button:hover {
    opacity: 0.7;
}

.priority {
    display: flex;
    justify-content: center;
    align-items: center;
}
.del-ticket {
    margin-left: 10px;
    font-size: 20px;
}
</style>

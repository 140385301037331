<template>
    <div id="view-root">
        <div id="ticket" v-if="ticketLoaded">
            <div id="info">
                <table>
                    <tr>
                        <td>Titre :</td>
                        <td>{{ticket.title}}</td>
                    </tr>
                    <tr>
                        <td>Description :</td>
                        <td>{{ticket.description}}</td>
                    </tr>
                    <tr>
                        <td>Utilisateur :</td>
                        <td>{{getUser(ticket.collaborator_id)}}</td>
                    </tr>
                    <tr>
                        <td>Créé le :</td>
                        <td>{{formatDate(ticket.created_at)}}</td>
                    </tr>
                </table>
            </div>
            <div id="update">
                <div class="status-div">
                    <h4>Priorité</h4>
                    <select v-model="newTicket.priority" class="input-form">
                        <option value="bas">Bas</option>
                        <option value="normal">Normale</option>
                        <option value="haute">Haute</option>
                    </select>
                </div>
                <div class="status-div">
                    <h4>Statut</h4>
                    <select v-model="newTicket.status" class="input-form">
                        <option value="nouveau">Nouveau</option>
                        <option value="en cours">En cours</option>
                        <option value="OK">Résolu</option>
                    </select>
                </div>
                <button @click="updateTicket()">Enregistrer</button>
            </div>
        </div>
    </div>
</template>

<script>
const axios = require('axios');
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
const findCollaborator = require('../../scripts/collaborator/findUserNameById');
export default {
    name: 'View Dev Ticket',
    data() {
        return {
            ticket: {},
            ticketLoaded: false,
            newTicket: {
                priority: null,
                status: null
            }
        }
    },
    methods: {
        updateTicket() {
            axios.put(`${this.$store.state.BaseUrl}/dev/${this.ticket.id}`, {
                status: this.newTicket.status,
                priority: this.newTicket.priority
            }, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                createToast({ title: "Ticket mis à jour !" }, { type: "success", showIcon: true, onClose: this.$router.go() });
            }).catch(err => {
                createToast({ title: "Erreur", description: "Erreur du serveur" }, { type: "danger", showIcon: true });
                console.log(err);
            });
        },
        getUser(id) {
            return findCollaborator(id, this.$store.state.UserList)
        }, 
        formatDate(date) {
            var opt = {
                timeZone: "Europe/Paris",
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "numeric",
                minute: "numeric"
            };
            return new Date(date).toLocaleDateString("fr-FR", opt);
        },
        fetchTicket(id) {
            axios.get(`${this.$store.state.BaseUrl}/dev/${id}`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                this.ticket = res.data;
                this.ticketLoaded = true;
                this.newTicket.priority = this.ticket.priority;
                this.newTicket.status = this.ticket.status;
            }).catch(err => console.log(err));
        } 
    },
    mounted() {
        if (!this.$route.params.id) {
            this.$router.push("/");
        }
        else {
            this.fetchTicket(this.$route.params.id);
        }
    },
}
</script>

<style scoped>
#info, #update {
    margin: 20px;
    background-color: whitesmoke;
    border-radius: 20px;
    padding: 10px;
    border: 2px solid lightseagreen;
    word-wrap: break-word;
}

button {
    width: 30%;
    margin-top: 20px;
    height: 40px;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    background-color: lightseagreen;
    color: white;
}

button:hover {
    background-color: white;
    color: lightseagreen;
    border: 1px solid lightseagreen;
}

.input-form {
    text-align: center;
    border-radius: 10px;
    height: 30px;
    width: 30%;
}

.input-form:focus {
    outline: none;
    border: 1px solid lightseagreen;
}
</style>

<template>
<div id="billing">
    <div id="billing-top">
        <h4>Client : {{customerName}}</h4>
        <div id="change-period">
                <span>Période : </span>
                <select v-model="period_idx" @change="updateDate()">
                  <option v-for="period in periods" :value="period.idx">{{period.name}}</option>
                </select>
              </div>
        <span>Total Période : <strong>{{getTotal()}}€</strong></span>
        <button>Facturer à ce jour</button>
    </div>
    <table>
    <tr>
        <th>Produit</th>
        <th>Prix (€)</th>
        <th>Quantité</th>
        <th>Statut</th>
    </tr>
    <tr v-for="order in orderList">
        <td>{{order.product_name}}</td>
        <td>{{order.product_price}}</td>
        <td>{{order.quantity}}</td>
        <td class="td-statut"><Badge :type="getType(order.status)" :text="getName(order.status).toUpperCase()"></Badge></td>
    </tr>
    </table>
</div>
</template>

<script>
const axios = require('axios');
import Badge from './utils/Badge.vue';
export default {
    name: "Billing",
    data() {
        return {
            orderList: [],
            period_idx: 4,
            periods: [
              {
                idx: 0,
                name: 'Janvier',
                month_code: '01',
              },
              {
                idx: 1,
                name: 'Février',
                month_code: '02',
              },
              {
                idx: 2,
                name: 'Mars',
                month_code: '03',
              },
              {
                idx: 3,
                name: 'Avril',
                month_code: '04',
              },
              {
                idx: 4,
                name: 'Mai',
                month_code: '05',
              },
              {
                idx: 5,
                name: 'Juin',
                month_code: '06',
              },
              {
                idx: 6,
                name: 'Juillet',
                month_code: '07',
              },
              {
                idx: 7,
                name: 'Août',
                month_code: '08',
              },
              {
                idx: 8,
                name: 'Septembre',
                month_code: '09',
              },
              {
                idx: 9,
                name: 'Octobre',
                month_code: '10',
              },
              {
                idx: 10,
                name: 'Novembre',
                month_code: '11',
              },
              {
                idx: 11,
                name: 'Décembre',
                month_code: '12',
              },
            ],
            startDate: '',
            endDate: ''
        }
    },
    props: {
        customerId: {
            required: true
        },
        customerName: {
            required: true
        }
    },
    components: { Badge },
    methods: {
      updateDate() {
          let monthNumber = this.period_idx + 1;

          // Calculate the first date by using the number variable to set the month and hard-coding the day and year
          let firstDate = new Date(new Date().getFullYear(), monthNumber-1, '01');

          // Calculate the second date by adding one month to the first date
          let secondDate = new Date(firstDate.getFullYear(), firstDate.getMonth()+1, firstDate.getDate());

          // Format the dates using the YYYY-MM-DD format
          this.startDate = firstDate.toISOString().slice(0, 10);
          this.endDate = secondDate.toISOString().slice(0, 10);
          this.getOrdersList();
        },
        getTotal() {
            let total = 0;
            this.orderList.forEach(order => {
                total = total + parseFloat(order.product_price * order.quantity);
            })
            return total.toFixed(2);
        },
        getName(status) {
            switch (status) {
                case "unpaid":
                    return "Non payé";
                case "paid":
                    return "Payé";
                case "alert":
                    return "Retard/Echec";
            }
        },
        getType(status) {
            switch (status) {
                case "unpaid":
                    return "info";
                case "paid":
                    return "success";
                case "alert":
                    return "danger";
                default:
                    return "warning";
            }
        },
        getCurrentMonth() {
            var date = new Date();
            var month = date.getMonth();
            return month;
        },
        getOrdersList() {
            axios.get(`${this.$store.state.BaseUrl}/billing/${this.customerId}/range?startDate=${this.startDate}&endDate=${this.endDate}`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                this.orderList = res.data;
                this.orderList = this.orderList.reverse();
            }).catch(err => {
                console.log(err);
            })
        }
    },
    mounted() {
        let d = new Date();
        let month = d.getMonth();
        let year = d.getFullYear();
        let endYear = year;
        let end = month + 2;
        if (month + 2 > 12) {
            end = '01';
            endYear = year + 1;
        } else {
            this.period_idx = month;
        }
        let start = month + 1;

        this.startDate = year + '-' + start + '-' + '01';
        this.endDate = endYear + '-' + end + '-' + '01';
        this.period_idx = this.getCurrentMonth();
        this.getOrdersList();
    }
}
</script>

<style scoped>

table {
    width: 100%;
}

#billing-top {
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.td-statut {
    display: flex;
    align-items: center;
    justify-content: center;
}

#billing-top button {
    cursor: pointer;
    width: 30%;
    border-radius: 10px;
    border: none;
    height: 30px;
    background-color: lightseagreen;
    color: white;
}

#billing-top button:hover {
    background-color: white;
    color: lightseagreen;
    border: 1px solid lightseagreen;
}

</style>

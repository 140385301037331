<template>
  <div id="component-root">
    <Upload class="upload" :url="$store.state.BaseUrl + '/products/import'" :name="'importFile'"></Upload>
  </div>
</template>

<script>
import Upload from '../Files/Upload.vue';
export default {
    name: "Import products",
    components: { Upload }
}
</script>

<style scoped>
.upload { 
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>

<template>
    <div id="dropdown">
        <button id="drop-btn">{{buttonText}}</button>
        <div class="drop-content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Dropdown',
    props: {
        buttonText: {
            type: String,
            required: true
        }
    }

}
</script>

<style scoped>
#dropdown {
    position: relative;
    display: inline-block;
}

.drop-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
}

#dropdown:hover .drop-content {
    display: block;
}

#drop-btn {
    padding: 5px;
    background-color: whitesmoke;
    color: lightseagreen;
    border: 1px solid lightseagreen;
    border-radius: 10px;
}

</style>

<template>
    <div id="view-root">
        <label>Nom du produit</label>
        <input v-model="form.name" type="text" placeholder="ex: CDD" class="input-form" required>
        <label>Prix</label>
        <input v-model="form.price" type="number" step="0.01" placeholder="19.99" class="input-form" required>
        <button @click="createProduct">Créer {{form.name}}</button>
    </div>
</template>

<script>
const axios = require('axios');
export default {
    name: 'Create Product',
    data() {
        return {
            form: {
                name: '',
                price: 0.00,
            }
        }
    },
    mounted() {
        if (this.$route.params.cpy) {
            this.form.name = this.$route.params.cpy.toLowerCase() + '_';
        }
    },
    methods: {
        createProduct() {
            if (!this.form.name || !this.form.name.length) {
                return;
            }
            axios.post(`${this.$store.state.BaseUrl}/products/new`, {
                name: this.form.name,
                price: this.form.price
            }, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                console.log(res);
                this.$router.push('/products');
            }).catch(err => {
                console.log(err)
            });
        }
    }
}
</script>

<style scoped>

#view-root {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.input-form {
    text-align: center;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    height: 30px;
    width: 20%;
}

input:focus {
    outline: none;
    border: 1px solid lightseagreen;
}

button {
    width: 20%;
    margin-top: 20px;
    height: 40px;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    background-color: lightseagreen;
    color: white;
}

button:hover {
    background-color: white;
    color: lightseagreen;
    border: 1px solid lightseagreen;
}

</style>

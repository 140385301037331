<template>
    <div id="component-root">
        <h3>Créer une tâche - {{customerName}}</h3>
        <div class="create">
            <label>Titre de la tâche</label><br>
            <input required type="text" placeholder="Effectuer ..." v-model="newTask.name"><br><br>
            <label>Date (jour)</label><br>
          <input type="datetime-local" id="taskTime" name="taskTime" v-model="newTask.day">
          <br><br>
          <input type="checkbox" id="recurring" name="recurring" v-model="newTask.recurring">
          {{ newTask.recurring ? 'Récurrent' : 'Non récurrent' }}
        </div>
        <button type="button" class="ok-btn" @click="postTask()">Créer</button>
    </div>
</template>

<script>
const axios = require('axios');
export default {
    name: 'Create Task',
    props: {
        customerId: {
            required: true
        },
        customerName: {
            required: true
        }
    },
    data() {
        return {
            newTask: {
                name: null,
                day: null,
                recurring: false,
            }
        }
    },
    methods: {
        postTask() {
            if (!this.newTask.name || !this.newTask.day) {
                return;
            }
            axios.post(`${this.$store.state.BaseUrl}/tasks/create/${this.customerId}`, {
                name: this.newTask.name,
                due_date: this.newTask.day,
                customer_id: this.customerId,
                recurring: this.newTask.recurring
            }, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                this.$router.go();
            }).catch(err => {
                console.log(err);
            })
        }
        
    },
    mounted() {

    },
    component: {}
}
</script>

<style scoped>
.ok-btn {
    width: 100%;
    margin-top: 10px;
    height: 40px;
    cursor: pointer;
    border-radius: 10px;
    color: white;
    background-color: lightseagreen;
    border: 1px solid lightseagreen;
}
</style>

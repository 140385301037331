<template>
    <div id="view-root">
      <Modal ref="popupImportProduct">
        <ImportProducts></ImportProducts>
        <button type="button" class="cancel-btn" @click="cancelImportProduct()">Annuler</button>
      </Modal>
        <div class="customers-top">
            <h3>Liste des produits</h3>
            <button class="import-btn" @click="ImportProducts()">Importer</button>
                <Dropdown class="sort" :buttonText="'Trier par'">
                    <ul>
                        <li v-if="isSorted" @click="sortProducts('normal')">Annuler le tri</li>
                        <li @click="sortProducts('croissant')">Prix croissant</li>
                        <li @click="sortProducts('decroissant')">Prix décroissant</li>
                    </ul>
                </Dropdown>
                <input type="search" @input="searchArray" placeholder="Chercher un produit...">

                <button @click="this.$router.push('/create-product')" class="add-button">+</button>
        </div>
        <table>
            <tr>
                <th>Id</th>
                <th>Nom</th>
                <th>Prix</th>
                <th>Actions</th>
            </tr>
            <tr v-for="product in products">
                <td>{{product.id}}</td>
                <td>{{product.name}}</td>
                <td>{{product.price}}€</td>
                <td><fa class="icon" icon="xmark" @click="DeleteProduct(product.id)"></fa></td>
            </tr>
        </table>
        <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
    </div>
</template>

<script>
const axios = require('axios');
const _ = require('underscore');
import ConfirmDialog from '../../components/UI/ConfirmDialog.vue';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import Dropdown from '../../components/utils/Dropdown.vue';
import Modal from '../../components/utils/Modal.vue';
import ImportProducts from '../../components/Excel/ImportProducts.vue';
export default {
    name: 'Products',
    data() {
        return {
            products: [],
            productsCpy: [],
            isSorted: false,
            productsSearch: [],
            isSearched: false,
            search: ''
        }
    },
    methods: {
      searchArray(e) {
            let input = e.data;
            if (this.isSearched) {
                this.products = this.productsSearch;
                this.isSearched = true;
            }
            this.productsSearch = this.products;
            if (input === null) {
                this.search = this.search.slice(0, this.search.length - 1);
            } else {
                this.search = this.search + input;
            }
            this.isSearched = true;
            this.products = this.products.filter(product => product.name.toUpperCase().includes(this.search.toUpperCase()));
        },
      ImportProducts() {
        this.$refs.popupImportProduct.open();
      },
      cancelImportProduct() {
        this.$refs.popupImportProduct.close();
      },
        sortProducts(type) {
            if (type === 'normal') {
                this.products = this.productsCpy;
                this.isSorted = false;
            }
            if (type === 'croissant') {
                if (this.isSorted) {
                    this.products = this.productsCpy;
                    this.isSorted = false;
                }
                this.productsCpy = this.products;
                this.products = _.sortBy(this.products, 'price');
                this.isSorted = true;
            }
            if (type === 'decroissant') {
                if (this.isSorted) {
                    this.products = this.productsCpy;
                    this.isSorted = false;
                }
                this.productsCpy = this.products;
                this.products = _.sortBy(this.products, 'price');
                this.products.reverse();
                this.isSorted = true;
            }
        },
        getAllProducts() {
            axios.get(`${this.$store.state.BaseUrl}/products/all`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                this.products = res.data;
                this.$store.commit('setProductsList', res.data);
            }).catch(err => {
                console.log(err);
            });
        },
        async DeleteProduct(id) {
            const ok = await this.$refs.confirmDialog.show({
                title: 'Supprimer le produit',
                message: 'Êtes vous sûr de vouloir supprimer ce produit ? Cette action est irréversible.',
                okButton: 'Supprimer',
            })
            if (ok) {
                axios.delete(`${this.$store.state.BaseUrl}/products/${id}`, {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.Bearer}`,
                    }
                }).then(res => {
                    createToast({ title: "Produit supprimé !" }, { type: "success", showIcon: true, onClose: this.$router.go() });
                }).catch(err => {
                    console.log(err);
                    createToast({ title: "Erreur", description: "Erreur du serveur" }, { type: "danger", showIcon: true });
                })
            }
        },
    },
    mounted() {
        this.getAllProducts();
    },
    components: {
    ConfirmDialog,
    Dropdown,
    Modal,
    ImportProducts
}
}
</script>

<style scoped>
table {
    width: 100%;
    margin: 2%;
}

.customers-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 2%;
    border-bottom: 2px solid lightseagreen;
}

.add-button {
    cursor: pointer;
    color: white;
    background-color: lightseagreen;
    border: none;
    font-size: 30px;
    border-radius: 50%;
    padding: 5px 15px 5px 15px;
    margin-bottom: 10px;
}

.add-button:hover {
    opacity: 0.7;
}

.icon {
    font-size: 20px;
    margin-right: 20px;
}

.sort {
    margin-inline: 40px;
}

li {
    cursor: pointer;
}

.import-btn {
    cursor: pointer;
    color: white;
    background-color: lightseagreen;
    border: none;
    font-size: 15px;
    border-radius: 5px;
    padding: 5px 15px 5px 15px;
}

.import-btn:hover {
    opacity: 0.7;
}

.cancel-btn {
    width: 100%;
    margin-top: 10px;
    height: 40px;
    cursor: pointer;
    border-radius: 10px;
    background-color: white;
    color: lightseagreen;

    border: 1px solid lightseagreen;
}

.cancel-btn:hover {
    background-color: lightseagreen;
    color: white;
    border: 1px solid white;
}
</style>

<template>
    <div id="view-root">
        <div class="customers-top">
            <h3>Tâches</h3>
            <div id="bill-bar">
              <div id="change-period">
                <span>Période : </span>
                <select v-model="period_idx" @change="updateDate()">
                  <option v-for="period in periods" :value="period.idx">{{period.name}}</option>
                </select>
              </div>
              <Dropdown class="sort" :buttonText="'Trier par'">
                    <ul>
                        <li v-if="isSorted" @click="sortTasks('normal')">Annuler le tri</li>
                        <li @click="sortTasks('alpha')">A-Z</li>
                        <li @click="sortTasks('complete')">Statut</li>
                        <li @click="sortTasks('date')">Date</li>
                    </ul>
                </Dropdown>
                <button @click="exportTasks">Exporter</button>
            </div>
        </div>
        <table>
                <tr>
                    <th>Client</th>
                    <th>Tâche</th>
                    <th>Date</th>
                    <th>Effectué</th>
                    <th>Actions</th>
                </tr>
                <tr v-for="task in tasks">
                    <td>{{getCpy(task.customer_id)}}</td>
                    <td>{{task.name}}</td>
                    <td>{{formatDate(task.due_date)}}</td>
                    <td>{{completion(task.completed)}}</td>
                    <td><fa icon="eye" @click="viewTask(task.id)"></fa></td>
                </tr>
            </table>
    </div>
</template>

<script>
import Dropdown from '../../components/utils/Dropdown.vue';
const axios = require('axios');
const _ = require('underscore');
const findWallet = require('../../scripts/wallets/fileWalletNameById');
const findCollab = require('../../scripts/collaborator/findUserNameById');
const findCustomerCompany = require('../../scripts/customer/findCompany');

export default {
    name: "Tasks",
    data() {
        return {
            id: null,
            tasksList: [],
            tasks: [],
            taskCpy: [],
            isSorted: false,
            tasksSearch: [],
            isSearched: false,
            search: "",
            period_idx: 4,
            periods: [
              {
                idx: 0,
                name: 'Janvier',
                month_code: '01',
              },
              {
                idx: 1,
                name: 'Février',
                month_code: '02',
              },
              {
                idx: 2,
                name: 'Mars',
                month_code: '03',
              },
              {
                idx: 3,
                name: 'Avril',
                month_code: '04',
              },
              {
                idx: 4,
                name: 'Mai',
                month_code: '05',
              },
              {
                idx: 5,
                name: 'Juin',
                month_code: '06',
              },
              {
                idx: 6,
                name: 'Juillet',
                month_code: '07',
              },
              {
                idx: 7,
                name: 'Août',
                month_code: '08',
              },
              {
                idx: 8,
                name: 'Septembre',
                month_code: '09',
              },
              {
                idx: 9,
                name: 'Octobre',
                month_code: '10',
              },
              {
                idx: 10,
                name: 'Novembre',
                month_code: '11',
              },
              {
                idx: 11,
                name: 'Décembre',
                month_code: '12',
              },
            ],
            startDate: '',
            endDate: ''
        };
    },
    methods: {
      exportTasks() {
          axios.get(`${this.$store.state.BaseUrl}/tasks/export`, {
            responseType: 'blob',
            headers: {
              'Authorization': `Bearer ${this.$store.state.Bearer}`
            }
          }).then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]), {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Taches.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove();
          }).catch(err => {
            console.log(err);
          })
        },
        formatDate(date) {
            var opt = {
                timeZone: "Europe/Paris",
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
            };
            return new Date(date).toLocaleDateString("fr-FR", opt);
        },
        completion(status) {
            if (status === 0) {
                return "Non";
            }
            return "Oui";
        },
        getCollab(id) {
            return findCollab(id, this.$store.state.UserList);
        },
        getWallet(id) {
            return findWallet(id, this.$store.state.WalletList);
        },
        getCpy(id) {
            return findCustomerCompany(id, this.$store.state.CustomersList);
        },
        searchArray(e) {
            let input = e.data;
            if (this.isSearched) {
                this.tasksList = this.tasksSearch;
                this.isSearched = true;
            }
            this.tasksSearch = this.tasksList;
            if (input === null) {
                this.search = this.search.slice(0, this.search.length - 1);
            }
            else {
                this.search = this.search + input;
            }
            this.isSearched = true;
            this.tasksList = this.tasksList.filter(task => task.cpy.toLowerCase().includes(this.search.toLowerCase()));
        },
        viewTask(id) {
            this.$router.push({ name: "View Task", params: { id: id } });
        },
        sortTasks(type) {
            if (type === "normal") {
                this.tasks = this.taskCpy;
                this.isSorted = false;
            }
            if (type === "date") {
                if (this.isSorted) {
                    this.tasks = this.taskCpy;
                    this.isSorted = false;
                }
                this.taskCpy = this.tasks;
                this.tasks = _.sortBy(this.tasks, "due_date");
                this.isSorted = true;
            }
            if (type === "alpha") {
                if (this.isSorted) {
                    this.tasks = this.taskCpy;
                    this.isSorted = false;
                }
                this.taskCpy = this.tasks;
                this.tasks = _.sortBy(this.tasks, "name");
                this.isSorted = true;
            }
            if (type === "complete") {
                if (this.isSorted) {
                    this.tasks = this.taskCpy;
                    this.isSorted = false;
                }
                this.taskCpy = this.tasks;
                this.tasks = _.sortBy(this.tasks, "completed");
                this.isSorted = true;
            }
        },
        getDate(obj) {
            if (obj.tasks === null) {
                return "Aucune tâche";
            }
            let dates = [];
            obj.tasks.forEach(task => {
                dates.push(task.day);
            });
            dates = dates.sort(function (a, b) { return a - b; });
            return `${dates[0]} - ${dates[dates.length - 1]}`;
        },
        getCurrentMonth() {
            var date = new Date();
            var month = date.getMonth();
            return month;
        },
        fetchTasks(month, year) {
            axios.get(`${this.$store.state.BaseUrl}/tasks/${month}/${year}`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                this.tasks = res.data;
            }).catch(err => console.log(err));
        },
        updateDate() {
          let m = this.periods[this.period_idx].month_code;
          let y = new Date().getFullYear();
          this.fetchTasks(m, y);
        }
    },
    mounted() {
        this.period_idx = this.getCurrentMonth();
        let d = new Date();
        this.fetchTasks(this.periods[this.period_idx].month_code, d.getFullYear());
    },
    components: { Dropdown }
}
</script>

<style scoped>
table {
    width: 100%;
    margin: 2%;
}

tr {
    margin-right: 20px;
}

.customers-top {
    padding: 5px;
    margin-inline: 2%;
    border-bottom: 2px solid lightseagreen;
}

.add-button {
    cursor: pointer;
    color: white;
    background-color: lightseagreen;
    border: none;
    font-size: 30px;
    border-radius: 50%;
    padding: 5px 15px 5px 15px;
    margin-bottom: 10px;
}

.add-button:hover {
    opacity: 0.7;
}

.sort-button {
    cursor: pointer;
    color: white;
    background-color: lightseagreen;
    border: none;
    font-size: 20px;
    border-radius: 10px;
    padding: 5px 15px 5px 15px;
    margin-bottom: 10px;
    margin-inline: 10px;
}

.sort-button:hover {
    color: lightseagreen;
    background-color: white;
    border: 1px solid lightseagreen;
}


.search {
  margin-inline: 20px;;
}

.error-message {
  text-align: center;
}

#bill-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.del-bill {
    margin-left: 10px;
    font-size: 20px;
}

li {
  cursor: pointer;
}
</style>

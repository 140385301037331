<template>
    <div id="view-root">
        <div id="create-ticket">
            <h2>Créer un ticket</h2>
            <label class="label-input">Titre</label>
            <input class="input-form" type="text" v-model="ticket.title" placeholder="Titre du problème" required>
            <label class="label-input">Description</label>
            <textarea class="input-form" v-model="ticket.description" placeholder="Description du problème"></textarea>
            <label class="label-input">Priorité</label>
            <select class="input-form" v-model="ticket.priority">
                <option value="bas">Bas</option>
                <option value="normal">Normale</option>
                <option value="haute">Haute</option>
            </select>
            <button @click="createTicket()" type="button">Créer</button>
            <span>Si possible communiquer log/capture d'écran par email à alexis.faure.dev@gmail.com</span>
            <span>Veuillez inclure le numéro de ticket dans l'objet du mail.</span>
        </div>
    </div>
</template>

<script>
const axios = require('axios');
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    name: 'Create Dev Ticket',
    data() {
        return {
            ticket: {
                title: null,
                description: null,
                collaborator_id: null,
                priority: "normal"
            }
        }
    },
    methods: {
        createTicket() {
            this.ticket.collaborator_id = this.$store.state.User.id;
            if (!this.ticket.collaborator_id || !this.ticket.priority || !this.ticket.title || !this.ticket.description) {
                return;
            }
            axios.post(`${this.$store.state.BaseUrl}/dev/new`, {
                title: this.ticket.title,
                description: this.ticket.description,
                collaborator_id: this.ticket.collaborator_id,
                priority: this.ticket.priority
            }, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                createToast({ title: "Ticket Créé !" }, { type: "success", showIcon: true, onClose: this.$router.push({name: 'Dev Tickets'}) });
            }).catch(err =>{
                createToast({ title: "Erreur", description: "Erreur du serveur" }, { type: "danger", showIcon: true });
                console.log(err)
            });
        }
    },
}
</script>

<style scoped>
#create-ticket {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.label-input {
   margin-top: 10px;
}

.input-form {
    text-align: center;
    border-radius: 10px;
    margin-bottom: 10px;
    height: 30px;
    width: 30%;
}
textarea.input-form {
    overflow: auto;
    outline: none;
    box-shadow: none;
    resize: none;
}

input:focus {
    outline: none;
    border: 1px solid lightseagreen;
}

button {
    width: 30%;
    margin-top: 20px;
    height: 40px;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    background-color: lightseagreen;
    color: white;
}

button:hover {
    background-color: white;
    color: lightseagreen;
    border: 1px solid lightseagreen;
}

/* Remove arrows for input type number*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}

::placeholder {
    text-align: center; 
}

/* or, for legacy browsers */
::-webkit-input-placeholder {
    text-align: center;
}

:-moz-placeholder { /* Firefox 18- */
    text-align: center;  
}

::-moz-placeholder {  /* Firefox 19+ */
    text-align: center;  
}

:-ms-input-placeholder {  
    text-align: center; 
}
</style>

<template>
    <div id="component-root">
        <label>Nom du portefeuille</label>
        <input type="text" v-model="name" placeholder="ex: Catimel" class="input-form" required>
        <button @click="renameWallet()">Modifier</button>
    </div> 
</template>

<script>

const axios = require('axios');
export default {
    name: 'Edit Wallet',
    data() {
        return {
            name: '',
            id: 0
        }
    },
    mounted() {
        if (!this.$route.params.id || !this.$route.params.name) {
            this.$router.push("/");
        }
        else {
            this.name = this.$route.params.name;
            this.id = this.$route.params.id;
        }
    },
    methods: {
        renameWallet() {
            if (!this.name || !this.name.length) {
                return;
            }
            axios.put(`${this.$store.state.BaseUrl}/wallets/${this.id}`, {
                name: this.name
            }, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                this.$router.push('/wallets');
            }).catch(err => {
                console.log(err);
            })
        }
    }
}
</script>

<style scoped>
#component-root {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.input-form {
    text-align: center;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-top: 20px;
    height: 30px;
    width: 20%;
}

input:focus {
    outline: none;
    border: 1px solid lightseagreen;
}

button {
    width: 20%;
    margin-top: 20px;
    height: 40px;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    background-color: lightseagreen;
    color: white;
}

button:hover {
    background-color: white;
    color: lightseagreen;
    border: 1px solid lightseagreen;
}

</style>

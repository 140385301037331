<template>
    <div id="profil-root" v-if="customerLoaded">
    <Modal ref="popupCreateTask">
        <CreateTask :customerId="customer.id" :customerName="customer.company"></CreateTask>
        <button type="button" class="cancel-btn" @click="cancelCreateTask()">Annuler</button>
    </Modal>
    <Modal ref="popupEditTask">
        <EditTasks :customerId="customer.id"></EditTasks>
        <button type="button" class="cancel-btn" @click="cancelEditTask()">Annuler</button>
    </Modal>
    <Modal ref="popupInvoice">
        <Invoice :name="customer.company" :id="customer.id" :wallet_id="customer.wallet_id"></Invoice>
        <button type="button" class="cancel-btn" @click="cancelInvoice()">Annuler</button>
    </Modal>
    <h1>{{customer.name}} - {{customer.company}}</h1>
    <div class="profil-toolbox">
        <button @click="editUser(customer.id)">Modifier le client</button>
        <button @click="DeleteUser(customer.id)">Supprimer le client</button>
        <button @click="invoiceNewProduct(customer.id)">Facturer un produit</button>
        <button @click="createProduct(customer.company)">Créer un produit pour ce client</button>
        <button @click="createTicket(customer.id)">Créer un ticket</button>
    </div>
    <div id="profil-container">
        <div class="profil-card">
            <h3>Informations</h3>
            <div id="customer-info">
                <table>
                    <tr>
                        <td>Entreprise :</td>
                        <td>{{customer.company}}</td>
                    </tr>
                    <tr>
                        <td>Email :</td>
                        <td>{{customer.email}}</td>
                    </tr>
                    <tr>
                        <td>Téléphone :</td>
                        <td>{{customer.phone}}</td>
                    </tr>
                    <tr>
                        <td>Portefeuille :</td>
                        <td>{{getWallet(customer.wallet_id)}}</td>
                    </tr>
                    <tr>
                      <td>Mode de facturation : </td>
                      <td>{{customer.bill_method}}</td>
                    </tr>
                    <tr>
                        <td>Collaborateur :</td>
                        <td>{{getName(customer.collaborator_id)}}</td>
                    </tr>
                    <tr>
                        <td>Numéro SIRET :</td>
                        <td>{{customer.siret}}</td>
                    </tr>
                    <tr>
                        <td>Numéro de TVA :</td>
                        <td>{{customer.vat}}</td>
                    </tr>
                    <tr>
                        <td>Code NAF</td>
                        <td>{{customer.naf}}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="profil-card">
            <h3>Post-It</h3>
            <textarea name="post-it" class="post-it-input" v-model="post_it">{{customer.post_it}}</textarea>
            <div class="post-it-buttons">
                <button @click="savePostIt()">Sauvegarder</button>
            </div>
        </div>
        <div class="profil-card textarea">
            <h3>Dossier Permanent</h3>
            <textarea name="post-it" class="post-it-input" v-model="permanent_folder">{{customer.permanent_folder}}</textarea>
            <div class="post-it-buttons">
                <button @click="saveFolder()">Sauvegarder</button>
            </div>
        </div>
        <div class="profil-card">
            <h3>Calendrier</h3>
            <button type="button" class="calendar-btn" @click="viewTask(customer.id)">Modifier les tâches</button>
            <button type="button" class="calendar-btn" @click="createTask()">Créer une tâche</button>
            <button type="button" class="calendar-btn" @click="initTasks(customer.id)">Réinitialiser tâches</button>
            <DatePicker is-expanded :min-date="new Date()" :attributes='calendarTasks' @dayclick="dayClick" v-if="customerLoaded"/>
        </div>
        <div class="profil-card">
            <h3>Tickets</h3>
            <UserTickets :userId="customer.id"></UserTickets>
        </div>
        <div class="profil-card">
            <h3 class="billing-h3">Facturation</h3>
            <Billing :customerId="customer.id" :customerName="customer.name !== ' ' ? customer.name : customer.company"></Billing>
        </div>
        <div class="profil-card">
            <h3>Emails</h3>
            <Emails :email="customer.email"></Emails>
        </div>
        <div class="profil-card">
          <h3>Produits</h3>
          <div class="product-list">
            <div class="product-item" v-for="product in products" :key="product.id">
              <div class="product-name">{{product.name}}</div>
              <div class="product-price">{{product.price}} €</div>
            </div>
            </div>
        </div>
    </div>
    <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
  </div>
</template>

<script>
const axios = require('axios');
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import Billing from '../../components/Billing.vue';
import 'v-calendar/dist/style.css';
import { Calendar, DatePicker } from 'v-calendar';
import Emails from '../../components/Emails/Emails.vue';
import ModalVue from '../../components/utils/Modal.vue';
import ConfirmDialog from '../../components/UI/ConfirmDialog.vue';
const findCollaboratorName = require('../../scripts/collaborator/findUserNameById');
const findWalletName = require('../../scripts/wallets/fileWalletNameById');
import UserTickets from '../../components/Tickets/UserTickets.vue';
import Modal from '../../components/utils/Modal.vue';
import Invoice from '../../components/Invoice.vue';
import CreateTask from '../../components/tasks/CreateTask.vue';
import EditTasks from '../../components/tasks/EditTasks.vue';

export default {
    data() {
        return {
            myUser: this.$store.state.User,
            customerLoaded: false,
            post_it: "post-it",
            permanent_folder: "permanent_folder",
            customer: {},
            products: [],
            tasksList : [],     
            tasks: [
                {
                    name: 'Jour de facturation',
                    date: new Date(),
                    color: 'green',
                    isComplete: false
                },
                {
                    name: 'Bulletins de paye',
                    date: new Date(),
                    color: 'pink',
                    isComplete: false
                },
                {
                    name: 'Effectuer DSN',
                    date: new Date(),
                    color: 'yellow',
                    isComplete: false
                },
            ],
        };
    },
    computed: {
        calendarTasks() {
            if (this.tasksList === [] || !this.tasksList) {
              return;
            }
            const date = new Date();
            const year = date.getFullYear();
            const month = date.getMonth();
            return [
                {
                    key: "Aujourd'hui",
                    highlight: {
                        fillMode: 'outline'
                    },
                    popover: {
                        label: "Aujourd'hui"
                    },
                    dates: new Date()
                },
                ...this.tasksList.map(task => ({
                    dates: task.due_date,
                    highlight: {
                        fillMode: 'solid',
                        color: task.color,
                        class: task.completed ? 'opacity-75' : ''
                    },
                    key: task.name,
                    popover: {
                        label: task.name
                    },
                    customData: task
                })),
            ]
        }
    },
    mounted() {
        if (!this.$route.params.id) {
            this.$router.push("/");
        }
        else {
            this.products = this.$store.state.ProductsList;
            this.getUser(this.$route.params.id);
            this.getPostIt(this.$route.params.id);
        }
    },
    beforeRouteUpdate(to, from, next) {
        this.getUser(to.params.id);
        next();
    },
    methods: {
        createProduct(cpy) {
            this.$router.push({ name: 'Create Product', params: { cpy: cpy }});
        },
        dayClick(day) {
            this.$router.push('/tasks/' + this.customer.id);
        },
        fetchTasks(id) {
            axios.get(`${this.$store.state.BaseUrl}/tasks/customer/${id}`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                this.tasksList = res.data;
                console.log(this.tasksList);
                this.customerLoaded = true;
            }).catch(err => console.log(err));
        },
        createTask() {
            this.$refs.popupCreateTask.open();
        },
        cancelCreateTask() {
            this.$refs.popupCreateTask.close();
        },
        editTask() {
            this.$refs.popupEditTask.open();
        },
        cancelEditTask() {
            this.$refs.popupEditTask.close();
        },
        cancelInvoice() {
            this.$refs.popupInvoice.close();
        },
        invoiceNewProduct(customerId) {
            this.$refs.popupInvoice.open();
        },
        editUser(id) {
            this.$router.push({ name: 'Edit Customer', params: { id: id}});
        },
        createTicket(id) {
            this.$router.push({ name: 'Create Ticket', params: {id: id} });
        },
        getWallet(id) {
            return findWalletName(id, this.$store.state.WalletList);
        },
        getName(id) {
            return findCollaboratorName(id, this.$store.state.UserList);
        },
        getPostIt(id) {
            axios.get(`${this.$store.state.BaseUrl}/customers/${id}/post_it`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`,
                }
            }).then(res => {
                this.post_it = res.data.post_it;
                this.permanent_folder = this.customer.permanent_folder;
            }).catch(err => {
                console.log(err);
            });
        },
        saveFolder() {
          axios.post(`${this.$store.state.BaseUrl}/customers/${this.customer.id}/permanent_folder`, {
                folder: this.permanent_folder
            }, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                createToast({ title: "Dossier Permanent sauvegardé" }, { type: "success", showIcon: true });
            }).catch(err => {
                console.log(err);
                return createToast({ title: "Erreur", description: "Erreur du serveur" }, { type: "danger", showIcon: true });
            });
        },
        savePostIt() {
            axios.post(`${this.$store.state.BaseUrl}/customers/${this.customer.id}/post_it`, {
                post_it: this.post_it
            }, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                createToast({ title: "Post-it sauvegardé" }, { type: "success", showIcon: true });
            }).catch(err => {
                console.log(err);
                return createToast({ title: "Erreur", description: "Erreur du serveur" }, { type: "danger", showIcon: true });
            });
        },
        getUser(id) {
            axios.get(`${this.$store.state.BaseUrl}/customers/${id}`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`,
                }
            }).then(res => {
                this.customer = res.data[0];
                this.products = this.products.filter(product => product.name.toUpperCase().includes(this.customer.company.toUpperCase()));
                this.fetchTasks(this.customer.id)
            }).catch(err => {
                console.log(err);
            });
        },
        async DeleteUser(id) {
            const ok = await this.$refs.confirmDialog.show({
                title: 'Supprimer le client',
                message: 'Êtes vous sûr de vouloir supprimer ce client ? Cette action est irréversible.',
                okButton: 'Supprimer',
            })
            if (ok) {
                axios.delete(`${this.$store.state.BaseUrl}/customers/${id}`, {
                    headers: {
                        Authorization: `Bearer ${this.$store.state.Bearer}`,
                    }
                }).then(res => {
                    createToast({ title: "Client supprimé !" }, { type: "success", showIcon: true, onClose: this.$router.push('/customers') });
                }).catch(err => {
                    console.log(err);
                    createToast({ title: "Erreur", description: "Erreur du serveur" }, { type: "danger", showIcon: true });
                })
            }
        },
        viewTask(id) {
            this.$router.push({ name: 'View Task', params: {id: id} });
        },
        initTasks(id) {
          axios.get(`${this.$store.state.BaseUrl}/tasks/init/${id}`, {
            headers: {
              Authorization: `Bearer ${this.$store.state.Bearer}`
            }
          }).then(res => {
            createToast({ title: "Tâches initialisées" }, { type: "success", showIcon: true });
            this.$router.go();
          }).catch(err => {
            console.log(err);
            createToast({ title: "Erreur", description: "Erreur du serveur" }, { type: "danger", showIcon: true });
          });
        },
    },
    components: {
    Calendar,
    DatePicker,
    Billing,
    Emails,
    ConfirmDialog,
    UserTickets,
    ModalVue,
    Modal,
    Invoice,
    CreateTask,
    EditTasks
}
}
</script>

<style>

.textarea {
  min-height: 250px;
}

#profil-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 20px;
}

.profil-toolbox {
    margin-top: 15px;
    display: flex;
    align-items: center;
    border-bottom: 2px solid lightseagreen;
    padding-bottom: 10px;
    justify-content: space-around;
}

.profil-toolbox button {
    cursor: pointer;
    width: 30%;
    border-radius: 20px;
    border: none;
    height: 30px;
    background-color: lightseagreen;
    color: white;
    margin-left: 10px;
    margin-right: 10px;
}

.profil-toolbox button:hover {
    background-color: white;
    color: lightseagreen;
    border: 1px solid lightseagreen;
}

.profil-card {
    padding-top: 20px;
    background-color: whitesmoke;
    width: 45%;
    border-radius: 10px;
    margin: 20px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    transition: 0.3s;
}

.profil-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

#customer-info table {
    text-align: start;
    margin-left: 30px;
    margin-bottom: 20px;
    font-size: 20px;
    
}

.post-it-input {
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
    height: 50%;
    background-color: whitesmoke;
    border: none;
    overflow: auto;
    outline: none;
    box-shadow: none;
    resize: none;
}

.post-it-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 20px;
}

.post-it-buttons button {
    cursor: pointer;
    width: 30%;
    border-radius: 20px;
    border: none;
    height: 30px;
    background-color: lightseagreen;
    color: white;
    margin-bottom: 20px;
}

.post-it-buttons button:hover {
    background-color: white;
    color: lightseagreen;
    border: 1px solid lightseagreen;
}

.billing-h3 {
    padding-bottom: 0;
    margin-bottom: 0;
}

.cancel-btn {
    width: 100%;
    margin-top: 10px;
    height: 40px;
    cursor: pointer;
    border-radius: 10px;
    background-color: white;
    color: lightseagreen;

    border: 1px solid lightseagreen;
}

.cancel-btn:hover {
    background-color: lightseagreen;
    color: white;
    border: 1px solid white;
}


.calendar-btn {
    width: 20%;
    margin-inline: 20px;
    margin-top: 10px;
    height: 40px;
    cursor: pointer;
    border-radius: 10px;
    background-color: white;
    color: lightseagreen;
    border: 1px solid lightseagreen;
}

.calendar-btn:hover {
    background-color: lightseagreen;
    color: white;
    border: 1px solid white;
}

.product-list {
    max-height: 200px;
    overflow: scroll;
}

.product-item {
  display: flex;
  justify-content: space-between;
  margin-inline: 20px;
}
</style>

<template>
  <div id="create-ticket">
    <h2>Créer un ticket</h2>
    <form>
        <label class="label-input">Titre</label>
        <input class="input-form" type="text" v-model="form.title" placeholder="Titre du problème" required>
        <label class="label-input">Description</label>
        <textarea class="input-form" v-model="form.description" placeholder="Description du problème"></textarea>
        <label class="label-input">Id client</label>
        <input class="input-form" type="number" v-model="form.customer_id" required>
        <label class="label-input">Priorité</label>
        <select class="input-form" v-model="form.priority">
            <option value="bas">Bas</option>
            <option value="normal">Normale</option>
            <option value="haute">Haute</option>
        </select>
        <label class="label-input">Date de fin</label>
        <div class="date-picker">
          <DatePicker v-model="date" is-expand></DatePicker>
        </div>
        <label>Collaborateur</label>
        <SelectUser class="input-form" :UserList="this.$store.state.UserList" @on-collab="handleCollab"></SelectUser>
        <button @click="createTicket()" type="button">Créer</button>
    </form>
  </div>
</template>

<script>
const axios = require('axios');
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import SelectUser from '../../components/utils/SelectUser.vue'
const findCollaboratorByName = require('../../scripts/collaborator/findUserIdByName');
import 'v-calendar/dist/style.css';
import { Calendar, DatePicker } from 'v-calendar';

export default {
    name: "Create Ticket",
    data() {
        return {
            form: {
                title: "",
                description: "",
                customer_id: 0,
                priority: "Normale",
                collaborator_id: 0,
                date: null,
            }
        };
    },
    mounted() {
        if (this.$route.params.id) {
            this.form.customer_id = this.$route.params.id;
        }
        this.date = new Date();
    },
    methods: {
        handleCollab(val) { 
            this.form.collaborator_id = val;
        },
        createTicket() {
            if (!this.form.title || !this.form.customer_id) {
                return;
            }
            if (this.form.title === "" || this.form.customer_id === 0) {
                return;
            }
            let d = this.date.toISOString().split('T');
            let date = d[0] + ' ' + d[1].split('.')[0];
            axios.post(`${this.$store.state.BaseUrl}/tickets/new`, {
                "title": this.form.title,
                "description": this.form.description,
                "priority": this.form.priority,
                "customer_id": this.form.customer_id,
                "wallet_id": 0,
                "collaborator_id": this.form.collaborator_id,
                "end_at": date,
            }, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                createToast({ title: "Ticket créé" }, { type: "success", showIcon: true, onClose: this.$router.push('/tickets') });
            }).catch(err => {
                console.log(err);
                createToast({ title: "Erreur", description: "Erreur interne" }, { type: "danger", showIcon: true });
            })
        }
    },
    components: { SelectUser, Calendar, DatePicker }
}
</script>

<style scoped>

#create-ticket {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30%;
}

.label-input {
   margin-top: 10px;
}

.input-form {
    text-align: center;
    border-radius: 10px;
    margin-bottom: 10px;
    height: 30px;
}
textarea.input-form {
    overflow: auto;
    outline: none;
    box-shadow: none;
    resize: none;
}

input:focus {
    outline: none;
    border: 1px solid lightseagreen;
}

button {
    width: 30%;
    margin-top: 20px;
    height: 40px;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    background-color: lightseagreen;
    color: white;
}

button:hover {
    background-color: white;
    color: lightseagreen;
    border: 1px solid lightseagreen;
}

/* Remove arrows for input type number*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}

::placeholder {
    text-align: center; 
}

/* or, for legacy browsers */
::-webkit-input-placeholder {
    text-align: center;
}

:-moz-placeholder { /* Firefox 18- */
    text-align: center;  
}

::-moz-placeholder {  /* Firefox 19+ */
    text-align: center;  
}

:-ms-input-placeholder {  
    text-align: center; 
}

</style>

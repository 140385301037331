<template>
    <span>{{total}}€</span>
</template>

<script>
const axios = require('axios');
export default {
    name: 'Wallet Amount',
    data() {
        return {
            total: 0
        }
    },
    props: {
        id: {
            required: true,
            type: Number
        }
    },
    methods: {
        getAmount(id) {
            axios.get(`${this.$store.state.BaseUrl}/wallets/amount/${id}`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                this.total = res.data.total;
            }).catch(err => {
                console.log(err);
            })
        }
    },
    mounted() {
        this.getAmount(this.id);
    }
}
</script>

<style>

</style>

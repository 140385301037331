<template>
    <div id="component-root">
        <form>
            <label>Sélectionnez le produit</label>
            <select id="select" class="input-form" @change="updateSelected($event)">
                <option selected disabled>Choisir</option>
                <option v-for="product in CompanyProducts" :value="product.id">{{product.name}}</option>
            </select>
            <label>Nom du produit*</label>
            <input type="text" class="input-form" v-model="selected.name">
            <label>Prix*</label>
            <input type="number" step="0.01" class="input-form" v-model="selected.price" required>
            <label v-if="selected.id">Quantité*</label>
            <input v-if="selected.id" type="number" class="input-form" v-model="qty" required>
            <label v-if="selected.id">Date*</label>
            <input v-if="selected.id" type="month" class="input-form" v-model="period" required>
            <label v-if="selected.id">Détail :</label>
            <input v-if="selected.id" type="text" class="input-form" v-model="message">
            <button @click="billProduct" type="button">Facturer</button>
        </form>
        <span v-if="selected.id">Vous allez facturer <strong>{{selected.price * qty}}€</strong> à <strong>{{name}}</strong> pour <br><strong>{{selected.name}}</strong></span>
    </div>
</template>

<script>
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
const axios = require('axios');
export default {
    name: 'Invoice',
    data() {
        return {
            Products: [], 
            CompanyProducts: [],
            message: "",
            selected: {},
            qty: 1,
            period: new Date()
        }
    },
    props: {
        name: {
            type: String,
            required: true
        },
        id: {
            type: Number,
            required: true
        },
        wallet_id: {
            type: Number,
            required: true
        }
    },
    methods: {
        sortProducts() {
            this.Products.forEach(product => {
                if (product.name.toLowerCase().includes(this.name.toLowerCase())) {
                    console.log(product);
                    this.CompanyProducts.push(product);
                }
            })
        },
        billProduct() {
            if (!this.selected.id) {
                return;
            }
            axios.post(`${this.$store.state.BaseUrl}/billing/new`, {
                id : this.id,
                name: this.selected.name,
                price: this.selected.price,
                wallet_id: this.wallet_id,
                quantity: this.qty,
                date: new Date(this.period).toISOString().slice(0, 19).replace('T', ' '),
                message: this.message
            }, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                createToast({ title: "Client facturé" }, { type: "success", showIcon: true, onClose: this.$router.go() });
            }).catch(err => {
                console.log(err);
            })
        },
        updateSelected(event) {
            let id = parseInt(event.target.value);
            this.Products.forEach(product => {
                if (product.id === id) {
                    this.selected = product;
                }
            });   
        }
    },
    mounted() {
        this.Products = this.$store.state.ProductsList;
        this.sortProducts();
    }
}
</script>

<style scoped>

form {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

button {
    width: 100%;
    margin-top: 20px;
    height: 40px;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    background-color: lightseagreen;
    color: white;
}

button:hover {
    background-color: white;
    color: lightseagreen;
    border: 1px solid lightseagreen;
}

.input-form {
    text-align: center;
    border-radius: 10px;
    height: 30px;
}

.input-form:focus {
    outline: none;
    border: 1px solid lightseagreen;
}

span {
    margin-top: 10px;
}

</style>

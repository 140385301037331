<template>
  <div class="billWallet">
    <label for="month-select">Période :</label>
    <select id="month-select" v-model="selectedMonth">
      <option value="1">Janvier</option>
      <option value="2">Février</option>
      <option value="3">Mars</option>
      <option value="4">Avril</option>
      <option value="5">Mai</option>
      <option value="6">Juin</option>
      <option value="7">Juillet</option>
      <option value="8">Août</option>
      <option value="9">Septembre</option>
      <option value="10">Octobre</option>
      <option value="11">Novembre</option>
      <option value="12">Décembre</option>
    </select>
    <label for="email">Email Portefeuille : </label>
    <input type="email" id="email" v-model="walletEmail">
    <button type="button" class="billButton" @click="billWallet">Facturer</button>
  </div>
</template>

<script>
const axios = require('axios');
export default {
  name: 'WalletBilling',
  props: {
    wId: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      selectedMonth: null,
      startDate: null,
      endDate: null,
      walletEmail: null,
    };
  },
  watch: {
    selectedMonth(month) {
      // calculate the start and end dates for the selected month
      const year = new Date().getFullYear(); // use the current year
      this.startDate = new Date(year, month - 1, 1).toISOString().substring(0, 10);
      this.endDate = new Date(year, month, 0).toISOString().substring(0, 10);
    },
  },
  methods: {
      billWallet() {
        if (!this.wId || !this.startDate || !this.endDate) {
          return;
        }
        axios.post(`${this.$store.state.BaseUrl}/billing/export/wallet/${this.wId}?startDate=${this.startDate}&endDate=${this.endDate}`, {
          walletEmail: this.walletEmail,
        }, {
          headers: {
            Authorization: `Bearer ${this.$store.state.Bearer}`,
          }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]), {type: 'text/csv'});
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Facturation_Portefeuille_${this.wId}_${this.startDate}-${this.endDate}.csv`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch((error) => {
          console.log(error);
        });
      },
  }
}
</script>

<style scoped>

.billWallet {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem;
}

.billButton {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.5rem;
  background-color: lightseagreen;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

</style>

<template>
  <div id="component-root">
    <input accept="text/csv" class="file-upload" id="upload" name="upload" type="file" ref="file" @change="upload">
    <label class="upload-btn" for="upload">Choisir un fichier à importer</label>
  </div>
</template>

<script>
const axios = require('axios');
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
  name: 'Upload Single file',
  props: {
    url: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
  },
  methods: {
    upload() {
      const formData = new FormData();
      formData.append(this.name, this.$refs.file.files[0]);
      axios.post(this.url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.$store.state.Bearer}`
        }
      }).then(res => {
        createToast('Fichier importé avec succès', {
          position: 'top-right',
          type: 'success',
          timeout: 3000,
          dismissible: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: 'button',
          icon: true,
          rtl: false,
          onClose: this.$router.go()
        });
      }).catch(err => {
        console.log(err);
        createToast('Une erreur est survenue', {
          type: 'error',
          position: 'top-right',
          icon: true,
          timeout: 3000
        });
      });
    }
  }
}
</script>

<style scoped>
.file-upload {
  width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.upload-btn {
  background-color: lightseagreen;
  color: white;
  border-radius: 15px;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  margin-block: 20px;
}

.upload-btn:hover {
  background-color: white;
  color: lightseagreen;
  border: 1px solid lightseagreen;
}
</style>

<template>
    <div id="component-root">
        <select name="customer" id="select-customer" @change="onInput">
            <option v-for="(customer, k) in CustomerList" v-bind:key="k" :value="customer.company" :selected="customer.id === customerId">{{customer.company}}</option>
        </select>
    </div>
</template>

<script>
export default {
    name: "select-customer",
    props: {
        CustomerList: {
            type: Array,
            required: true
        },
        CustomerId: {
            type: Number,
            required: true
        }
    },
    methods: {
        onInput(e) {
            this.$emit('on-customer', e.target.value)
        }
    }
}
</script>

<style scoped>
select {
    border-radius: 10px;
    height: 35px;
    width: 100%;
    text-align: center;
}

select:focus {
    outline: none;
    border: 1px solid lightseagreen;
}

::placeholder {
    text-align: center; 
}

/* or, for legacy browsers */
::-webkit-input-placeholder {
    text-align: center;
}

:-moz-placeholder { /* Firefox 18- */
    text-align: center;  
}

::-moz-placeholder {  /* Firefox 19+ */
    text-align: center;  
}

:-ms-input-placeholder {  
    text-align: center; 
}
</style>

findWalletName = (id, WalletList) => {
    var name = "Portefeuille inconnu";
    WalletList.forEach(element => {
        if (element.id === id) {
            name = element.name
            return name;
        }
    });
    return name;
}

module.exports = findWalletName;

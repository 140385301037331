<template>
    <div id="view-root">
        <div class="form-edit">
            <div class="form-div">
                <div class="label-input">
                    <label for="name">Nom</label>
                    <input class="input-form" type="text" name="name" placeholder="John Doe" v-model="NewCustomer.name" required/>
                </div>
                <div class="label-input">
                    <label for="company">Entreprise</label>
                    <input class="input-form" type="text" name="company" placeholder="Nom + Dénomination" v-model="NewCustomer.company" required/>
                </div>
            </div>
            <div class="form-div">
                <div class="label-input">
                    <label for="address">Adresse</label>
                    <input class="input-form" type="text" name="address" v-model="NewCustomer.adress" placeholder="Numéro et rue" required/>
                </div>
                <div class="label-input">
                    <label for="zip">Code postal</label>
                    <input class="input-form" type="number" name="zip" v-model="NewCustomer.zip" placeholder="XXXXX" required/>
                </div>
                <div class="label-input">
                    <label for="city">Ville</label>
                    <input class="input-form" type="text" name="city" v-model="NewCustomer.city" placeholder="ex: Paris" required/>
                </div>
                <div class="label-input">
                    <label for="country">Pays</label>
                    <input class="input-form" type="text" name="country" v-model="NewCustomer.country" placeholder="FR" required/>
                </div> 
            </div>
            <div class="form-div">
                <div class="label-input">
                    <label for="email">Email</label>
                    <input class="input-form" type="email" name="email" placeholder="Adresse email" v-model="NewCustomer.email" required/>
                </div>
                <div class="label-input">
                    <label for="phone">Téléphone</label>
                    <input class="input-form" type="tel" name="phone" placeholder="Numéro de téléphone" v-model="NewCustomer.phone" pattern="^(?:0|\(?\+33\)?\s?|0033\s?)[1-79](?:[\.\-\s]?\d\d){4}$" required/>
                </div>
            </div>
            <div class="form-div">
                <div class="label-input">
                    <label for="wallet">Portefeuille</label>
                    <SelectWallet class="input-form" :walletList="$store.state.WalletList" :walletId="NewCustomer.wallet_id" @on-wallet="handleWallet"></SelectWallet>
                </div>
                <div class="label-input">
                    <label for="collaborator">Collaborateur</label>
                    <SelectUser :UserList="$store.state.UserList" :UserId="NewCustomer.collaborator_id" @on-collab="handleCollab" ></SelectUser>
                </div>
            </div>
            <div class="form-div">
                <div class="label-input">
                    <label for="bill_day">Jour de facturation (max 30)</label>
                    <input class="input-form" type="number" name="bill_day" v-model="NewCustomer.bill_day" placeholder="XX" max="30" min="1" required/>
                </div>
                <div class="label-input">
                    <label for="bill_method">Méthode de facturation</label>
                    <select class="input-form" name="bill_method" v-model="NewCustomer.bill_method">
                        <option value="cheque">Chèque</option>
                        <option value="virement">Virement</option>
                        <option value="prelevement">Prélèvement</option>
                        <option value="especes">Espèces</option>
                        <option value="cb">Carte Bancaire</option>
                    </select>
                </div>
                <div class="label-input">
                  <label for="siret">Numéro siret</label>
                  <input class="input-form" type="text" name="siret" v-model="NewCustomer.siret">
                </div>
                <div class="label-input">
                  <label for="naf">Code NAF</label>
                  <input class="input-form" type="text" name="naf" v-model="NewCustomer.naf">
                </div>
                <div class="label-input">
                  <label for="vat">Numéro de TVA</label>
                  <input class="input-form" type="text" name="vat" v-model="NewCustomer.vat">
                </div>
            </div>
            <div class="form-submit">
                <button type="button" @click="submitNewCustomer()">Sauvegarder</button>
            </div>
        </div>    
    </div>
</template>

<script>
const axios = require('axios');
import SelectWallet from '../../components/utils/SelectWallet.vue';
import SelectUser from '../../components/utils/SelectUser.vue';
export default {
    name: 'Edit Customer',
    data() {
        return {
            NewCustomer: {}
        }
    },
    components: {
        SelectUser, SelectWallet
    },
    methods: {
        getCustomer(id) {
            axios.get(`${this.$store.state.BaseUrl}/customers/${id}`, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                this.NewCustomer = res.data[0];
            }).catch(err => {
                console.log(err);
            })
        },
        handleCollab(val) { 
            this.NewCustomer.collaborator_id = val;
        },
        handleWallet(val) { 
            this.NewCustomer.wallet_id = val;
        },
        submitNewCustomer() {
          console.log(this.NewCustomer);
            axios.put(`${this.$store.state.BaseUrl}/customers/${this.NewCustomer.id}`, {
                name: this.NewCustomer.name,
                company: this.NewCustomer.company,
                adress: this.NewCustomer.adress,
                zip: this.NewCustomer.zip,
                country: this.NewCustomer.country,
                email: this.NewCustomer.email,
                phone: this.NewCustomer.phone,
                wallet_id: this.NewCustomer.wallet_id,
                collaborator_id: this.NewCustomer.collaborator_id,
                bill_day: this.NewCustomer.bill_day,
                bill_method: this.NewCustomer.bill_method,
                siret: this.NewCustomer.siret,
                naf: this.NewCustomer.naf,
                vat: this.NewCustomer.vat
            }, {
                headers: {
                    Authorization: `Bearer ${this.$store.state.Bearer}`
                }
            }).then(res => {
                this.$router.push('/customers/' + this.$route.params.id);
            }).catch(err => {
                console.log(err);
            })
        },
    },
    mounted() {
        if (!this.$route.params.id) {
            this.$router.push("/");
        }
        else {
            this.getCustomer(this.$route.params.id);
        }
    }
}
</script>

<style scoped>

.form-edit{ 
    margin-left: 2%;
    margin-right: 2%;
}
.form-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.label-input {
    display: flex;
    flex-direction: column;
    width: 45%;
    margin-top: 10px;
}

.input-form {
    text-align: center;
    border-radius: 10px;
    height: 30px;
}

input:focus {
    outline: none;
    border: 1px solid lightseagreen;
}

.form-submit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

button {
    width: 30%;
    margin-top: 20px;
    height: 40px;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    background-color: lightseagreen;
    color: white;
}

button:hover {
    background-color: white;
    color: lightseagreen;
    border: 1px solid lightseagreen;
}

/* Remove arrows for input type number*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}

::placeholder {
    text-align: center; 
}

/* or, for legacy browsers */
::-webkit-input-placeholder {
    text-align: center;
}

:-moz-placeholder { /* Firefox 18- */
    text-align: center;  
}

::-moz-placeholder {  /* Firefox 19+ */
    text-align: center;  
}

:-ms-input-placeholder {  
    text-align: center; 
}

</style>

<template>
  <div id="view-root" v-if="loaded">
    <h3>Modifier les informations du ticket {{ticket.id}}</h3>
    <form class="edit-ticket">
      <label class="label-input">Titre</label>
      <input class="input-form" v-model="ticket.title">
      <label class="label-input">Description</label>
      <textarea class="input-form" v-model="ticket.description"></textarea>
      <label class="label-input">Id Client (⚠️)</label>
      <input class="input-form" v-model="ticket.customer_id">
      <label class="label-input">Date de fin</label>
      <DatePicker v-model="date"></DatePicker>
      <label class="label-input">Collaborateur</label>
      <SelectUser :UserId="ticket.collaborator_id" :UserList="$store.state.UserList" @on-collab="handleUser" class="input-form"></SelectUser>
      <button type="button" @click="editTicket">Modifier</button>
    </form>
  </div>
</template>

<script>
const axios = require('axios');
import 'v-calendar/dist/style.css';
import { Calendar, DatePicker } from 'v-calendar';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import SelectUser from '../../components/utils/SelectUser.vue';
export default {
  name: 'Edit ticket',
  data() {
    return {
      ticket: {},
      date: null,
      loaded: false,
    };
  },
  methods: {
    handleUser(val) {
      this.ticket.collaborator_id = val;
    },
    editTicket() {
      let d = this.date.toISOString().split('T');
      let date = d[0] + ' ' + d[1].split('.')[0];
      this.ticket.end_at = date;
      if (!this.ticket.end_at || !this.ticket.title || !this.ticket.description || !this.ticket.customer_id) {
        return;
      }
      axios.put(`${this.$store.state.BaseUrl}/tickets/info/${this.ticket.id}`, this.ticket, {
        headers: {
          Authorization: `Bearer ${this.$store.state.Bearer}`,
        }
      }).then((res) => {
        createToast({ title: "Ticket modifié" }, { type: "success", showIcon: true, onClose: this.$router.push('/tickets/' + this.ticket.id) });
      }).catch((err) => console.log(err));
    },
    getTicket(id) {
      axios.get(`${this.$store.state.BaseUrl}/tickets/${id}`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.Bearer}`,
        },
      }).then((res) => {
        this.ticket = res.data;
        this.loaded=true;
      }).catch((err) => {
        console.log(err);
      });
    },
  },
  mounted() {
    if (!this.$route.params.id) {
      this.$router.push('/');
    }
    else {
      this.getTicket(this.$route.params.id);
      if (this.ticket.end_at) {
        this.date = this.ticket.end_at.toISOString();
      }
    }
  },
  components: { Calendar, DatePicker, SelectUser },
}
</script>

<style>

.edit-ticket {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}


.edit-ticket label, .edit-ticket input, .edit-ticket textarea {
  width : 40%;
}

button {
    width: 20%;
    margin-top: 20px;
    height: 40px;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    background-color: lightseagreen;
    color: white;
}

button:hover {
    background-color: white;
    color: lightseagreen;
    border: 1px solid lightseagreen;
}
</style>

<template>
  <modal ref="popup">
    <div v-html="html">
    </div>
    <button class="cancel-btn" @click="_cancel">Fermer</button>
  </modal>
</template>

<script>
import Modal from '../utils/Modal.vue'
export default {
    components: { Modal },
    data() {
        return {
            html: undefined,
            // Private variables
            resolvePromise: undefined,
            rejectPromise: undefined,
        }
    },
    methods: {
        viewEmail(opts = {}) {
            this.html = opts.html;
            this.$refs.popup.open()
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
        },
        _confirm() {
            this.$refs.popup.close()
            this.resolvePromise(true)
        },
        _cancel() {
            this.$refs.popup.close()
            this.resolvePromise(false)
        },
    }
}
</script>

<style>
.cancel-btn {
    padding: 0.5em 1em;
    background-color: #d5eae7;
    color: #35907f;
    border: 2px solid #0ec5a4;
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
}
</style>
